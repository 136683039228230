import clsx from 'clsx';

import chevronRight from '../../assets/images/icons/chevron_right.svg';
import { IProps } from '../../interfaces/components/vehicleCard.interface';

const VehicleCard = ({
  vehicle,
  statusLabel,
  handleClick,
  chipAdditionaClassNames,
  disabled,
  additionalClassNames,
}: IProps) => {
  const { nickname, displayName, displayVin } = vehicle;

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <button type="button" className={clsx('vehicle-card', additionalClassNames)} disabled={disabled} onClick={handleClick}>
      <div className="card-content">
        <p className="nickname">{nickname}</p>
        <p className="year-model">{displayName}</p>
        <p className="vin">{displayVin}</p>
        <div className={clsx('chip', chipAdditionaClassNames)}><p>{statusLabel}</p></div>
      </div>
      <img src={chevronRight} alt="right arrow" />
    </button>
  );
};

export default VehicleCard;

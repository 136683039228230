import { useState } from 'react';
import clsx from 'clsx';

import { formatIconPath } from '../../contexts/Theme';
import Chevron from '../../assets/images/icons/chevron.svg?component';
import InlineStyledText from '../InlineStyledText';
import { IProps } from '../../interfaces/components/accordionListItem';

const AccordionListItem = ({ image, title, description }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <li className="list-item list-item-custom-bullet">
      <button
        type="button"
        className="accordion-container button"
        onClick={handleClick}
      >
        <div className="flex align-center">
          <span>
            <img
              src={formatIconPath(image.url)}
              alt={image.alt}
              className="block"
            />
          </span>
          <span className="p-l-sm list-item-text">{title.text}</span>
          <Chevron className={clsx('m-l-xs m-r-xs', isOpen && 'rotate-180')} />
        </div>
        <div className={`accordion-content ${isOpen ? 'open' : 'closed'}`}>
          <InlineStyledText text={description.text} />
        </div>
      </button>
    </li>
  );
};

export default AccordionListItem;

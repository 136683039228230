import clsx from 'clsx';

import { useContext } from 'react';
import { FormContext } from '../Form/Form';

import { IProps } from '../../interfaces/components/buttonOption.interface';
import { IFormContext } from '../../interfaces/components/form.interface';

const ButtonOption = ({
  title,
  subtitle,
  icon,
  value,
  id,
}: IProps) => {
  const context = useContext<IFormContext | undefined>(FormContext);

  const getHandleClick = (buttonOptionValue: string) => () => {
    if (context) {
      context.setFormState({
        selectedButtonOption: buttonOptionValue,
      });
    }
  };

  // eslint-disable-next-line max-len
  const selectedButtonOption = (context?.formState as { selectedButtonOption?: string })?.selectedButtonOption;

  return (
    <button
      type="button"
      className={clsx(
        selectedButtonOption === value && 'selected',
        'button-option',
      )}
      id={id || value}
      onClick={getHandleClick(value)}
    >
      {icon && <img src={icon} alt="" className={clsx(subtitle && 'p-r-xs')} />}
      <div>
        <div className="button-option-title">{title}</div>
        {subtitle && <div>{subtitle}</div>}
      </div>
    </button>
  );
};

export default ButtonOption;

import { createContext, useContext, useState } from 'react';

import { IModalContext, IProps } from '../interfaces/contexts/modal.interface';

export const ModalContext = createContext<IModalContext | undefined>(undefined);

export const useProvideModal = () => {
  const [view, setView] = useState<string | null>(null);

  const openModal = (modalView: string) => setView(modalView);
  const closeModal = () => setView(null);

  return {
    view,
    openModal,
    closeModal,
  };
};

export const ProvideModal = ({ children }: IProps) => {
  const modalValue = useProvideModal();
  return (
    <ModalContext.Provider value={modalValue}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error('useModal must be used within the ProvideModal provider');
  }

  return context;
};

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import TextInput from '../../../TextInput';

import { formatIconPath } from '../../../../contexts/Theme';
import { IBrandSearch } from '../../../../interfaces/components/brandSearch.interface';
import Header from '../../../Header';

const BrandSearch = (props: IBrandSearch) => {
  const {
    searchBar,
    searchValue,
    handleChange,
    resetSearch,
    disabled,
  } = props;

  const {
    icon: searchIcon,
    id: searchId,
    name,
    placeholder,
    inputType,
  } = searchBar;

  const onInputChange = (text: string) => {
    if (handleChange) {
      handleChange(text);
    }
  };

  window.addEventListener('scroll', () => {
    const brandSearchContainer = document.querySelector('.brand-search-container');
    const scrolledDown = window.scrollY > 25;

    if (brandSearchContainer) {
      if (scrolledDown) {
        brandSearchContainer.classList.add('is-sticky');
      } else {
        brandSearchContainer.classList.remove('is-sticky');
      }
    }
  });

  return (
    <>
      <Header
        title={{ text: searchBar.header }}
        className="header-m-b-none"
      />
      <div className="brand-search-container">
        <TextInput
          icon={searchIcon}
          id={searchId}
          handleChange={onInputChange}
          name={name}
          placeholder={placeholder}
          required={false}
          inputType={inputType}
          value={searchValue}
          disabled={disabled}
        />
        {searchValue.length !== 0 && (
          <button
            id="clear"
            type="button"
            className="clear-search"
            onClick={resetSearch}
          >
            <img
              src={formatIconPath('../../../../assets/images/icons/exit.svg')}
              alt="Exit"
            />
          </button>
        )}
      </div>
    </>
  );
};

export default BrandSearch;

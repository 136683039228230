/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const rivian = $root.rivian = (() => {

    /**
     * Namespace rivian.
     * @exports rivian
     * @namespace
     */
    const rivian = {};

    rivian.Container = (function() {

        /**
         * Properties of a Container.
         * @memberof rivian
         * @interface IContainer
         * @property {rivian.IUnsignedMsgList|null} [unsignedMsgs] Container unsignedMsgs
         * @property {rivian.ISignedMsgList|null} [signedMsgs] Container signedMsgs
         */

        /**
         * Constructs a new Container.
         * @memberof rivian
         * @classdesc Represents a Container.
         * @implements IContainer
         * @constructor
         * @param {rivian.IContainer=} [properties] Properties to set
         */
        function Container(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Container unsignedMsgs.
         * @member {rivian.IUnsignedMsgList|null|undefined} unsignedMsgs
         * @memberof rivian.Container
         * @instance
         */
        Container.prototype.unsignedMsgs = null;

        /**
         * Container signedMsgs.
         * @member {rivian.ISignedMsgList|null|undefined} signedMsgs
         * @memberof rivian.Container
         * @instance
         */
        Container.prototype.signedMsgs = null;

        /**
         * Creates a new Container instance using the specified properties.
         * @function create
         * @memberof rivian.Container
         * @static
         * @param {rivian.IContainer=} [properties] Properties to set
         * @returns {rivian.Container} Container instance
         */
        Container.create = function create(properties) {
            return new Container(properties);
        };

        /**
         * Encodes the specified Container message. Does not implicitly {@link rivian.Container.verify|verify} messages.
         * @function encode
         * @memberof rivian.Container
         * @static
         * @param {rivian.IContainer} message Container message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Container.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.unsignedMsgs != null && Object.hasOwnProperty.call(message, "unsignedMsgs"))
                $root.rivian.UnsignedMsgList.encode(message.unsignedMsgs, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.signedMsgs != null && Object.hasOwnProperty.call(message, "signedMsgs"))
                $root.rivian.SignedMsgList.encode(message.signedMsgs, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Container message, length delimited. Does not implicitly {@link rivian.Container.verify|verify} messages.
         * @function encodeDelimited
         * @memberof rivian.Container
         * @static
         * @param {rivian.IContainer} message Container message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Container.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Container message from the specified reader or buffer.
         * @function decode
         * @memberof rivian.Container
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {rivian.Container} Container
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Container.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.rivian.Container();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.unsignedMsgs = $root.rivian.UnsignedMsgList.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.signedMsgs = $root.rivian.SignedMsgList.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Container message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof rivian.Container
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {rivian.Container} Container
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Container.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Container message.
         * @function verify
         * @memberof rivian.Container
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Container.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.unsignedMsgs != null && message.hasOwnProperty("unsignedMsgs")) {
                let error = $root.rivian.UnsignedMsgList.verify(message.unsignedMsgs);
                if (error)
                    return "unsignedMsgs." + error;
            }
            if (message.signedMsgs != null && message.hasOwnProperty("signedMsgs")) {
                let error = $root.rivian.SignedMsgList.verify(message.signedMsgs);
                if (error)
                    return "signedMsgs." + error;
            }
            return null;
        };

        /**
         * Creates a Container message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof rivian.Container
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {rivian.Container} Container
         */
        Container.fromObject = function fromObject(object) {
            if (object instanceof $root.rivian.Container)
                return object;
            let message = new $root.rivian.Container();
            if (object.unsignedMsgs != null) {
                if (typeof object.unsignedMsgs !== "object")
                    throw TypeError(".rivian.Container.unsignedMsgs: object expected");
                message.unsignedMsgs = $root.rivian.UnsignedMsgList.fromObject(object.unsignedMsgs);
            }
            if (object.signedMsgs != null) {
                if (typeof object.signedMsgs !== "object")
                    throw TypeError(".rivian.Container.signedMsgs: object expected");
                message.signedMsgs = $root.rivian.SignedMsgList.fromObject(object.signedMsgs);
            }
            return message;
        };

        /**
         * Creates a plain object from a Container message. Also converts values to other types if specified.
         * @function toObject
         * @memberof rivian.Container
         * @static
         * @param {rivian.Container} message Container
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Container.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.unsignedMsgs = null;
                object.signedMsgs = null;
            }
            if (message.unsignedMsgs != null && message.hasOwnProperty("unsignedMsgs"))
                object.unsignedMsgs = $root.rivian.UnsignedMsgList.toObject(message.unsignedMsgs, options);
            if (message.signedMsgs != null && message.hasOwnProperty("signedMsgs"))
                object.signedMsgs = $root.rivian.SignedMsgList.toObject(message.signedMsgs, options);
            return object;
        };

        /**
         * Converts this Container to JSON.
         * @function toJSON
         * @memberof rivian.Container
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Container.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Container
         * @function getTypeUrl
         * @memberof rivian.Container
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Container.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/rivian.Container";
        };

        return Container;
    })();

    rivian.UnsignedMsgList = (function() {

        /**
         * Properties of an UnsignedMsgList.
         * @memberof rivian
         * @interface IUnsignedMsgList
         * @property {Array.<rivian.IMsg>|null} [msgs] UnsignedMsgList msgs
         */

        /**
         * Constructs a new UnsignedMsgList.
         * @memberof rivian
         * @classdesc Represents an UnsignedMsgList.
         * @implements IUnsignedMsgList
         * @constructor
         * @param {rivian.IUnsignedMsgList=} [properties] Properties to set
         */
        function UnsignedMsgList(properties) {
            this.msgs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UnsignedMsgList msgs.
         * @member {Array.<rivian.IMsg>} msgs
         * @memberof rivian.UnsignedMsgList
         * @instance
         */
        UnsignedMsgList.prototype.msgs = $util.emptyArray;

        /**
         * Creates a new UnsignedMsgList instance using the specified properties.
         * @function create
         * @memberof rivian.UnsignedMsgList
         * @static
         * @param {rivian.IUnsignedMsgList=} [properties] Properties to set
         * @returns {rivian.UnsignedMsgList} UnsignedMsgList instance
         */
        UnsignedMsgList.create = function create(properties) {
            return new UnsignedMsgList(properties);
        };

        /**
         * Encodes the specified UnsignedMsgList message. Does not implicitly {@link rivian.UnsignedMsgList.verify|verify} messages.
         * @function encode
         * @memberof rivian.UnsignedMsgList
         * @static
         * @param {rivian.IUnsignedMsgList} message UnsignedMsgList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnsignedMsgList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.msgs != null && message.msgs.length)
                for (let i = 0; i < message.msgs.length; ++i)
                    $root.rivian.Msg.encode(message.msgs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UnsignedMsgList message, length delimited. Does not implicitly {@link rivian.UnsignedMsgList.verify|verify} messages.
         * @function encodeDelimited
         * @memberof rivian.UnsignedMsgList
         * @static
         * @param {rivian.IUnsignedMsgList} message UnsignedMsgList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnsignedMsgList.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UnsignedMsgList message from the specified reader or buffer.
         * @function decode
         * @memberof rivian.UnsignedMsgList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {rivian.UnsignedMsgList} UnsignedMsgList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnsignedMsgList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.rivian.UnsignedMsgList();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.msgs && message.msgs.length))
                            message.msgs = [];
                        message.msgs.push($root.rivian.Msg.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UnsignedMsgList message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof rivian.UnsignedMsgList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {rivian.UnsignedMsgList} UnsignedMsgList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnsignedMsgList.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UnsignedMsgList message.
         * @function verify
         * @memberof rivian.UnsignedMsgList
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UnsignedMsgList.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.msgs != null && message.hasOwnProperty("msgs")) {
                if (!Array.isArray(message.msgs))
                    return "msgs: array expected";
                for (let i = 0; i < message.msgs.length; ++i) {
                    let error = $root.rivian.Msg.verify(message.msgs[i]);
                    if (error)
                        return "msgs." + error;
                }
            }
            return null;
        };

        /**
         * Creates an UnsignedMsgList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof rivian.UnsignedMsgList
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {rivian.UnsignedMsgList} UnsignedMsgList
         */
        UnsignedMsgList.fromObject = function fromObject(object) {
            if (object instanceof $root.rivian.UnsignedMsgList)
                return object;
            let message = new $root.rivian.UnsignedMsgList();
            if (object.msgs) {
                if (!Array.isArray(object.msgs))
                    throw TypeError(".rivian.UnsignedMsgList.msgs: array expected");
                message.msgs = [];
                for (let i = 0; i < object.msgs.length; ++i) {
                    if (typeof object.msgs[i] !== "object")
                        throw TypeError(".rivian.UnsignedMsgList.msgs: object expected");
                    message.msgs[i] = $root.rivian.Msg.fromObject(object.msgs[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an UnsignedMsgList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof rivian.UnsignedMsgList
         * @static
         * @param {rivian.UnsignedMsgList} message UnsignedMsgList
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UnsignedMsgList.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.msgs = [];
            if (message.msgs && message.msgs.length) {
                object.msgs = [];
                for (let j = 0; j < message.msgs.length; ++j)
                    object.msgs[j] = $root.rivian.Msg.toObject(message.msgs[j], options);
            }
            return object;
        };

        /**
         * Converts this UnsignedMsgList to JSON.
         * @function toJSON
         * @memberof rivian.UnsignedMsgList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UnsignedMsgList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UnsignedMsgList
         * @function getTypeUrl
         * @memberof rivian.UnsignedMsgList
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UnsignedMsgList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/rivian.UnsignedMsgList";
        };

        return UnsignedMsgList;
    })();

    rivian.SignedMsgList = (function() {

        /**
         * Properties of a SignedMsgList.
         * @memberof rivian
         * @interface ISignedMsgList
         * @property {Uint8Array|null} [msgsData] SignedMsgList msgsData
         * @property {number|null} [sequenceNum] SignedMsgList sequenceNum
         * @property {boolean|null} [excludesDeviceNonce] SignedMsgList excludesDeviceNonce
         * @property {boolean|null} [excludesVehicleNonce] SignedMsgList excludesVehicleNonce
         * @property {rivian.ISignature|null} [signature] SignedMsgList signature
         */

        /**
         * Constructs a new SignedMsgList.
         * @memberof rivian
         * @classdesc Represents a SignedMsgList.
         * @implements ISignedMsgList
         * @constructor
         * @param {rivian.ISignedMsgList=} [properties] Properties to set
         */
        function SignedMsgList(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SignedMsgList msgsData.
         * @member {Uint8Array} msgsData
         * @memberof rivian.SignedMsgList
         * @instance
         */
        SignedMsgList.prototype.msgsData = $util.newBuffer([]);

        /**
         * SignedMsgList sequenceNum.
         * @member {number} sequenceNum
         * @memberof rivian.SignedMsgList
         * @instance
         */
        SignedMsgList.prototype.sequenceNum = 0;

        /**
         * SignedMsgList excludesDeviceNonce.
         * @member {boolean} excludesDeviceNonce
         * @memberof rivian.SignedMsgList
         * @instance
         */
        SignedMsgList.prototype.excludesDeviceNonce = false;

        /**
         * SignedMsgList excludesVehicleNonce.
         * @member {boolean} excludesVehicleNonce
         * @memberof rivian.SignedMsgList
         * @instance
         */
        SignedMsgList.prototype.excludesVehicleNonce = false;

        /**
         * SignedMsgList signature.
         * @member {rivian.ISignature|null|undefined} signature
         * @memberof rivian.SignedMsgList
         * @instance
         */
        SignedMsgList.prototype.signature = null;

        /**
         * Creates a new SignedMsgList instance using the specified properties.
         * @function create
         * @memberof rivian.SignedMsgList
         * @static
         * @param {rivian.ISignedMsgList=} [properties] Properties to set
         * @returns {rivian.SignedMsgList} SignedMsgList instance
         */
        SignedMsgList.create = function create(properties) {
            return new SignedMsgList(properties);
        };

        /**
         * Encodes the specified SignedMsgList message. Does not implicitly {@link rivian.SignedMsgList.verify|verify} messages.
         * @function encode
         * @memberof rivian.SignedMsgList
         * @static
         * @param {rivian.ISignedMsgList} message SignedMsgList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SignedMsgList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.msgsData != null && Object.hasOwnProperty.call(message, "msgsData"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.msgsData);
            if (message.sequenceNum != null && Object.hasOwnProperty.call(message, "sequenceNum"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.sequenceNum);
            if (message.excludesDeviceNonce != null && Object.hasOwnProperty.call(message, "excludesDeviceNonce"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.excludesDeviceNonce);
            if (message.excludesVehicleNonce != null && Object.hasOwnProperty.call(message, "excludesVehicleNonce"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.excludesVehicleNonce);
            if (message.signature != null && Object.hasOwnProperty.call(message, "signature"))
                $root.rivian.Signature.encode(message.signature, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SignedMsgList message, length delimited. Does not implicitly {@link rivian.SignedMsgList.verify|verify} messages.
         * @function encodeDelimited
         * @memberof rivian.SignedMsgList
         * @static
         * @param {rivian.ISignedMsgList} message SignedMsgList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SignedMsgList.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SignedMsgList message from the specified reader or buffer.
         * @function decode
         * @memberof rivian.SignedMsgList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {rivian.SignedMsgList} SignedMsgList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SignedMsgList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.rivian.SignedMsgList();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.msgsData = reader.bytes();
                        break;
                    }
                case 2: {
                        message.sequenceNum = reader.uint32();
                        break;
                    }
                case 3: {
                        message.excludesDeviceNonce = reader.bool();
                        break;
                    }
                case 4: {
                        message.excludesVehicleNonce = reader.bool();
                        break;
                    }
                case 5: {
                        message.signature = $root.rivian.Signature.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SignedMsgList message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof rivian.SignedMsgList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {rivian.SignedMsgList} SignedMsgList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SignedMsgList.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SignedMsgList message.
         * @function verify
         * @memberof rivian.SignedMsgList
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SignedMsgList.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.msgsData != null && message.hasOwnProperty("msgsData"))
                if (!(message.msgsData && typeof message.msgsData.length === "number" || $util.isString(message.msgsData)))
                    return "msgsData: buffer expected";
            if (message.sequenceNum != null && message.hasOwnProperty("sequenceNum"))
                if (!$util.isInteger(message.sequenceNum))
                    return "sequenceNum: integer expected";
            if (message.excludesDeviceNonce != null && message.hasOwnProperty("excludesDeviceNonce"))
                if (typeof message.excludesDeviceNonce !== "boolean")
                    return "excludesDeviceNonce: boolean expected";
            if (message.excludesVehicleNonce != null && message.hasOwnProperty("excludesVehicleNonce"))
                if (typeof message.excludesVehicleNonce !== "boolean")
                    return "excludesVehicleNonce: boolean expected";
            if (message.signature != null && message.hasOwnProperty("signature")) {
                let error = $root.rivian.Signature.verify(message.signature);
                if (error)
                    return "signature." + error;
            }
            return null;
        };

        /**
         * Creates a SignedMsgList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof rivian.SignedMsgList
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {rivian.SignedMsgList} SignedMsgList
         */
        SignedMsgList.fromObject = function fromObject(object) {
            if (object instanceof $root.rivian.SignedMsgList)
                return object;
            let message = new $root.rivian.SignedMsgList();
            if (object.msgsData != null)
                if (typeof object.msgsData === "string")
                    $util.base64.decode(object.msgsData, message.msgsData = $util.newBuffer($util.base64.length(object.msgsData)), 0);
                else if (object.msgsData.length >= 0)
                    message.msgsData = object.msgsData;
            if (object.sequenceNum != null)
                message.sequenceNum = object.sequenceNum >>> 0;
            if (object.excludesDeviceNonce != null)
                message.excludesDeviceNonce = Boolean(object.excludesDeviceNonce);
            if (object.excludesVehicleNonce != null)
                message.excludesVehicleNonce = Boolean(object.excludesVehicleNonce);
            if (object.signature != null) {
                if (typeof object.signature !== "object")
                    throw TypeError(".rivian.SignedMsgList.signature: object expected");
                message.signature = $root.rivian.Signature.fromObject(object.signature);
            }
            return message;
        };

        /**
         * Creates a plain object from a SignedMsgList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof rivian.SignedMsgList
         * @static
         * @param {rivian.SignedMsgList} message SignedMsgList
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SignedMsgList.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.msgsData = "";
                else {
                    object.msgsData = [];
                    if (options.bytes !== Array)
                        object.msgsData = $util.newBuffer(object.msgsData);
                }
                object.sequenceNum = 0;
                object.excludesDeviceNonce = false;
                object.excludesVehicleNonce = false;
                object.signature = null;
            }
            if (message.msgsData != null && message.hasOwnProperty("msgsData"))
                object.msgsData = options.bytes === String ? $util.base64.encode(message.msgsData, 0, message.msgsData.length) : options.bytes === Array ? Array.prototype.slice.call(message.msgsData) : message.msgsData;
            if (message.sequenceNum != null && message.hasOwnProperty("sequenceNum"))
                object.sequenceNum = message.sequenceNum;
            if (message.excludesDeviceNonce != null && message.hasOwnProperty("excludesDeviceNonce"))
                object.excludesDeviceNonce = message.excludesDeviceNonce;
            if (message.excludesVehicleNonce != null && message.hasOwnProperty("excludesVehicleNonce"))
                object.excludesVehicleNonce = message.excludesVehicleNonce;
            if (message.signature != null && message.hasOwnProperty("signature"))
                object.signature = $root.rivian.Signature.toObject(message.signature, options);
            return object;
        };

        /**
         * Converts this SignedMsgList to JSON.
         * @function toJSON
         * @memberof rivian.SignedMsgList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SignedMsgList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SignedMsgList
         * @function getTypeUrl
         * @memberof rivian.SignedMsgList
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SignedMsgList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/rivian.SignedMsgList";
        };

        return SignedMsgList;
    })();

    rivian.Signature = (function() {

        /**
         * Properties of a Signature.
         * @memberof rivian
         * @interface ISignature
         * @property {rivian.SignatureType|null} [type] Signature type
         * @property {Uint8Array|null} [data] Signature data
         */

        /**
         * Constructs a new Signature.
         * @memberof rivian
         * @classdesc Represents a Signature.
         * @implements ISignature
         * @constructor
         * @param {rivian.ISignature=} [properties] Properties to set
         */
        function Signature(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Signature type.
         * @member {rivian.SignatureType} type
         * @memberof rivian.Signature
         * @instance
         */
        Signature.prototype.type = 0;

        /**
         * Signature data.
         * @member {Uint8Array} data
         * @memberof rivian.Signature
         * @instance
         */
        Signature.prototype.data = $util.newBuffer([]);

        /**
         * Creates a new Signature instance using the specified properties.
         * @function create
         * @memberof rivian.Signature
         * @static
         * @param {rivian.ISignature=} [properties] Properties to set
         * @returns {rivian.Signature} Signature instance
         */
        Signature.create = function create(properties) {
            return new Signature(properties);
        };

        /**
         * Encodes the specified Signature message. Does not implicitly {@link rivian.Signature.verify|verify} messages.
         * @function encode
         * @memberof rivian.Signature
         * @static
         * @param {rivian.ISignature} message Signature message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Signature.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.data);
            return writer;
        };

        /**
         * Encodes the specified Signature message, length delimited. Does not implicitly {@link rivian.Signature.verify|verify} messages.
         * @function encodeDelimited
         * @memberof rivian.Signature
         * @static
         * @param {rivian.ISignature} message Signature message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Signature.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Signature message from the specified reader or buffer.
         * @function decode
         * @memberof rivian.Signature
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {rivian.Signature} Signature
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Signature.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.rivian.Signature();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.int32();
                        break;
                    }
                case 2: {
                        message.data = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Signature message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof rivian.Signature
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {rivian.Signature} Signature
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Signature.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Signature message.
         * @function verify
         * @memberof rivian.Signature
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Signature.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.data != null && message.hasOwnProperty("data"))
                if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                    return "data: buffer expected";
            return null;
        };

        /**
         * Creates a Signature message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof rivian.Signature
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {rivian.Signature} Signature
         */
        Signature.fromObject = function fromObject(object) {
            if (object instanceof $root.rivian.Signature)
                return object;
            let message = new $root.rivian.Signature();
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "NONE":
            case 0:
                message.type = 0;
                break;
            case "HMAC_SHA256":
            case 1:
                message.type = 1;
                break;
            }
            if (object.data != null)
                if (typeof object.data === "string")
                    $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                else if (object.data.length >= 0)
                    message.data = object.data;
            return message;
        };

        /**
         * Creates a plain object from a Signature message. Also converts values to other types if specified.
         * @function toObject
         * @memberof rivian.Signature
         * @static
         * @param {rivian.Signature} message Signature
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Signature.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.type = options.enums === String ? "NONE" : 0;
                if (options.bytes === String)
                    object.data = "";
                else {
                    object.data = [];
                    if (options.bytes !== Array)
                        object.data = $util.newBuffer(object.data);
                }
            }
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.rivian.SignatureType[message.type] === undefined ? message.type : $root.rivian.SignatureType[message.type] : message.type;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
            return object;
        };

        /**
         * Converts this Signature to JSON.
         * @function toJSON
         * @memberof rivian.Signature
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Signature.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Signature
         * @function getTypeUrl
         * @memberof rivian.Signature
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Signature.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/rivian.Signature";
        };

        return Signature;
    })();

    /**
     * SignatureType enum.
     * @name rivian.SignatureType
     * @enum {number}
     * @property {number} NONE=0 NONE value
     * @property {number} HMAC_SHA256=1 HMAC_SHA256 value
     */
    rivian.SignatureType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NONE"] = 0;
        values[valuesById[1] = "HMAC_SHA256"] = 1;
        return values;
    })();

    rivian.Msg = (function() {

        /**
         * Properties of a Msg.
         * @memberof rivian
         * @interface IMsg
         * @property {number|null} [transactionId] Msg transactionId
         * @property {rivian.IAuth|null} [auth] Msg auth
         * @property {rivian.IClientInfo|null} [clientInfo] Msg clientInfo
         */

        /**
         * Constructs a new Msg.
         * @memberof rivian
         * @classdesc Represents a Msg.
         * @implements IMsg
         * @constructor
         * @param {rivian.IMsg=} [properties] Properties to set
         */
        function Msg(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Msg transactionId.
         * @member {number} transactionId
         * @memberof rivian.Msg
         * @instance
         */
        Msg.prototype.transactionId = 0;

        /**
         * Msg auth.
         * @member {rivian.IAuth|null|undefined} auth
         * @memberof rivian.Msg
         * @instance
         */
        Msg.prototype.auth = null;

        /**
         * Msg clientInfo.
         * @member {rivian.IClientInfo|null|undefined} clientInfo
         * @memberof rivian.Msg
         * @instance
         */
        Msg.prototype.clientInfo = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * Msg content.
         * @member {"auth"|"clientInfo"|undefined} content
         * @memberof rivian.Msg
         * @instance
         */
        Object.defineProperty(Msg.prototype, "content", {
            get: $util.oneOfGetter($oneOfFields = ["auth", "clientInfo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Msg instance using the specified properties.
         * @function create
         * @memberof rivian.Msg
         * @static
         * @param {rivian.IMsg=} [properties] Properties to set
         * @returns {rivian.Msg} Msg instance
         */
        Msg.create = function create(properties) {
            return new Msg(properties);
        };

        /**
         * Encodes the specified Msg message. Does not implicitly {@link rivian.Msg.verify|verify} messages.
         * @function encode
         * @memberof rivian.Msg
         * @static
         * @param {rivian.IMsg} message Msg message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Msg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.transactionId != null && Object.hasOwnProperty.call(message, "transactionId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.transactionId);
            if (message.auth != null && Object.hasOwnProperty.call(message, "auth"))
                $root.rivian.Auth.encode(message.auth, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.clientInfo != null && Object.hasOwnProperty.call(message, "clientInfo"))
                $root.rivian.ClientInfo.encode(message.clientInfo, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Msg message, length delimited. Does not implicitly {@link rivian.Msg.verify|verify} messages.
         * @function encodeDelimited
         * @memberof rivian.Msg
         * @static
         * @param {rivian.IMsg} message Msg message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Msg.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Msg message from the specified reader or buffer.
         * @function decode
         * @memberof rivian.Msg
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {rivian.Msg} Msg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Msg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.rivian.Msg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.transactionId = reader.uint32();
                        break;
                    }
                case 5: {
                        message.auth = $root.rivian.Auth.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.clientInfo = $root.rivian.ClientInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Msg message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof rivian.Msg
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {rivian.Msg} Msg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Msg.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Msg message.
         * @function verify
         * @memberof rivian.Msg
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Msg.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                if (!$util.isInteger(message.transactionId))
                    return "transactionId: integer expected";
            if (message.auth != null && message.hasOwnProperty("auth")) {
                properties.content = 1;
                {
                    let error = $root.rivian.Auth.verify(message.auth);
                    if (error)
                        return "auth." + error;
                }
            }
            if (message.clientInfo != null && message.hasOwnProperty("clientInfo")) {
                if (properties.content === 1)
                    return "content: multiple values";
                properties.content = 1;
                {
                    let error = $root.rivian.ClientInfo.verify(message.clientInfo);
                    if (error)
                        return "clientInfo." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Msg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof rivian.Msg
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {rivian.Msg} Msg
         */
        Msg.fromObject = function fromObject(object) {
            if (object instanceof $root.rivian.Msg)
                return object;
            let message = new $root.rivian.Msg();
            if (object.transactionId != null)
                message.transactionId = object.transactionId >>> 0;
            if (object.auth != null) {
                if (typeof object.auth !== "object")
                    throw TypeError(".rivian.Msg.auth: object expected");
                message.auth = $root.rivian.Auth.fromObject(object.auth);
            }
            if (object.clientInfo != null) {
                if (typeof object.clientInfo !== "object")
                    throw TypeError(".rivian.Msg.clientInfo: object expected");
                message.clientInfo = $root.rivian.ClientInfo.fromObject(object.clientInfo);
            }
            return message;
        };

        /**
         * Creates a plain object from a Msg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof rivian.Msg
         * @static
         * @param {rivian.Msg} message Msg
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Msg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.transactionId = 0;
            if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                object.transactionId = message.transactionId;
            if (message.auth != null && message.hasOwnProperty("auth")) {
                object.auth = $root.rivian.Auth.toObject(message.auth, options);
                if (options.oneofs)
                    object.content = "auth";
            }
            if (message.clientInfo != null && message.hasOwnProperty("clientInfo")) {
                object.clientInfo = $root.rivian.ClientInfo.toObject(message.clientInfo, options);
                if (options.oneofs)
                    object.content = "clientInfo";
            }
            return object;
        };

        /**
         * Converts this Msg to JSON.
         * @function toJSON
         * @memberof rivian.Msg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Msg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Msg
         * @function getTypeUrl
         * @memberof rivian.Msg
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Msg.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/rivian.Msg";
        };

        return Msg;
    })();

    rivian.Auth = (function() {

        /**
         * Properties of an Auth.
         * @memberof rivian
         * @interface IAuth
         * @property {rivian.ICredentials|null} [credentials] Auth credentials
         */

        /**
         * Constructs a new Auth.
         * @memberof rivian
         * @classdesc Represents an Auth.
         * @implements IAuth
         * @constructor
         * @param {rivian.IAuth=} [properties] Properties to set
         */
        function Auth(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Auth credentials.
         * @member {rivian.ICredentials|null|undefined} credentials
         * @memberof rivian.Auth
         * @instance
         */
        Auth.prototype.credentials = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * Auth content.
         * @member {"credentials"|undefined} content
         * @memberof rivian.Auth
         * @instance
         */
        Object.defineProperty(Auth.prototype, "content", {
            get: $util.oneOfGetter($oneOfFields = ["credentials"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Auth instance using the specified properties.
         * @function create
         * @memberof rivian.Auth
         * @static
         * @param {rivian.IAuth=} [properties] Properties to set
         * @returns {rivian.Auth} Auth instance
         */
        Auth.create = function create(properties) {
            return new Auth(properties);
        };

        /**
         * Encodes the specified Auth message. Does not implicitly {@link rivian.Auth.verify|verify} messages.
         * @function encode
         * @memberof rivian.Auth
         * @static
         * @param {rivian.IAuth} message Auth message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Auth.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.credentials != null && Object.hasOwnProperty.call(message, "credentials"))
                $root.rivian.Credentials.encode(message.credentials, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Auth message, length delimited. Does not implicitly {@link rivian.Auth.verify|verify} messages.
         * @function encodeDelimited
         * @memberof rivian.Auth
         * @static
         * @param {rivian.IAuth} message Auth message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Auth.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Auth message from the specified reader or buffer.
         * @function decode
         * @memberof rivian.Auth
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {rivian.Auth} Auth
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Auth.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.rivian.Auth();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.credentials = $root.rivian.Credentials.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Auth message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof rivian.Auth
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {rivian.Auth} Auth
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Auth.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Auth message.
         * @function verify
         * @memberof rivian.Auth
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Auth.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.credentials != null && message.hasOwnProperty("credentials")) {
                properties.content = 1;
                {
                    let error = $root.rivian.Credentials.verify(message.credentials);
                    if (error)
                        return "credentials." + error;
                }
            }
            return null;
        };

        /**
         * Creates an Auth message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof rivian.Auth
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {rivian.Auth} Auth
         */
        Auth.fromObject = function fromObject(object) {
            if (object instanceof $root.rivian.Auth)
                return object;
            let message = new $root.rivian.Auth();
            if (object.credentials != null) {
                if (typeof object.credentials !== "object")
                    throw TypeError(".rivian.Auth.credentials: object expected");
                message.credentials = $root.rivian.Credentials.fromObject(object.credentials);
            }
            return message;
        };

        /**
         * Creates a plain object from an Auth message. Also converts values to other types if specified.
         * @function toObject
         * @memberof rivian.Auth
         * @static
         * @param {rivian.Auth} message Auth
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Auth.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.credentials != null && message.hasOwnProperty("credentials")) {
                object.credentials = $root.rivian.Credentials.toObject(message.credentials, options);
                if (options.oneofs)
                    object.content = "credentials";
            }
            return object;
        };

        /**
         * Converts this Auth to JSON.
         * @function toJSON
         * @memberof rivian.Auth
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Auth.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Auth
         * @function getTypeUrl
         * @memberof rivian.Auth
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Auth.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/rivian.Auth";
        };

        return Auth;
    })();

    rivian.Credentials = (function() {

        /**
         * Properties of a Credentials.
         * @memberof rivian
         * @interface ICredentials
         * @property {Uint8Array|null} [id] Credentials id
         * @property {Uint8Array|null} [nonce] Credentials nonce
         */

        /**
         * Constructs a new Credentials.
         * @memberof rivian
         * @classdesc Represents a Credentials.
         * @implements ICredentials
         * @constructor
         * @param {rivian.ICredentials=} [properties] Properties to set
         */
        function Credentials(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Credentials id.
         * @member {Uint8Array} id
         * @memberof rivian.Credentials
         * @instance
         */
        Credentials.prototype.id = $util.newBuffer([]);

        /**
         * Credentials nonce.
         * @member {Uint8Array} nonce
         * @memberof rivian.Credentials
         * @instance
         */
        Credentials.prototype.nonce = $util.newBuffer([]);

        /**
         * Creates a new Credentials instance using the specified properties.
         * @function create
         * @memberof rivian.Credentials
         * @static
         * @param {rivian.ICredentials=} [properties] Properties to set
         * @returns {rivian.Credentials} Credentials instance
         */
        Credentials.create = function create(properties) {
            return new Credentials(properties);
        };

        /**
         * Encodes the specified Credentials message. Does not implicitly {@link rivian.Credentials.verify|verify} messages.
         * @function encode
         * @memberof rivian.Credentials
         * @static
         * @param {rivian.ICredentials} message Credentials message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Credentials.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.nonce != null && Object.hasOwnProperty.call(message, "nonce"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.nonce);
            return writer;
        };

        /**
         * Encodes the specified Credentials message, length delimited. Does not implicitly {@link rivian.Credentials.verify|verify} messages.
         * @function encodeDelimited
         * @memberof rivian.Credentials
         * @static
         * @param {rivian.ICredentials} message Credentials message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Credentials.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Credentials message from the specified reader or buffer.
         * @function decode
         * @memberof rivian.Credentials
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {rivian.Credentials} Credentials
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Credentials.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.rivian.Credentials();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.bytes();
                        break;
                    }
                case 2: {
                        message.nonce = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Credentials message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof rivian.Credentials
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {rivian.Credentials} Credentials
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Credentials.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Credentials message.
         * @function verify
         * @memberof rivian.Credentials
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Credentials.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.nonce != null && message.hasOwnProperty("nonce"))
                if (!(message.nonce && typeof message.nonce.length === "number" || $util.isString(message.nonce)))
                    return "nonce: buffer expected";
            return null;
        };

        /**
         * Creates a Credentials message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof rivian.Credentials
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {rivian.Credentials} Credentials
         */
        Credentials.fromObject = function fromObject(object) {
            if (object instanceof $root.rivian.Credentials)
                return object;
            let message = new $root.rivian.Credentials();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length >= 0)
                    message.id = object.id;
            if (object.nonce != null)
                if (typeof object.nonce === "string")
                    $util.base64.decode(object.nonce, message.nonce = $util.newBuffer($util.base64.length(object.nonce)), 0);
                else if (object.nonce.length >= 0)
                    message.nonce = object.nonce;
            return message;
        };

        /**
         * Creates a plain object from a Credentials message. Also converts values to other types if specified.
         * @function toObject
         * @memberof rivian.Credentials
         * @static
         * @param {rivian.Credentials} message Credentials
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Credentials.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                if (options.bytes === String)
                    object.nonce = "";
                else {
                    object.nonce = [];
                    if (options.bytes !== Array)
                        object.nonce = $util.newBuffer(object.nonce);
                }
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.nonce != null && message.hasOwnProperty("nonce"))
                object.nonce = options.bytes === String ? $util.base64.encode(message.nonce, 0, message.nonce.length) : options.bytes === Array ? Array.prototype.slice.call(message.nonce) : message.nonce;
            return object;
        };

        /**
         * Converts this Credentials to JSON.
         * @function toJSON
         * @memberof rivian.Credentials
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Credentials.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Credentials
         * @function getTypeUrl
         * @memberof rivian.Credentials
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Credentials.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/rivian.Credentials";
        };

        return Credentials;
    })();

    rivian.ClientInfo = (function() {

        /**
         * Properties of a ClientInfo.
         * @memberof rivian
         * @interface IClientInfo
         * @property {rivian.IMobileInfo|null} [mobileInfo] ClientInfo mobileInfo
         */

        /**
         * Constructs a new ClientInfo.
         * @memberof rivian
         * @classdesc Represents a ClientInfo.
         * @implements IClientInfo
         * @constructor
         * @param {rivian.IClientInfo=} [properties] Properties to set
         */
        function ClientInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ClientInfo mobileInfo.
         * @member {rivian.IMobileInfo|null|undefined} mobileInfo
         * @memberof rivian.ClientInfo
         * @instance
         */
        ClientInfo.prototype.mobileInfo = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ClientInfo content.
         * @member {"mobileInfo"|undefined} content
         * @memberof rivian.ClientInfo
         * @instance
         */
        Object.defineProperty(ClientInfo.prototype, "content", {
            get: $util.oneOfGetter($oneOfFields = ["mobileInfo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ClientInfo instance using the specified properties.
         * @function create
         * @memberof rivian.ClientInfo
         * @static
         * @param {rivian.IClientInfo=} [properties] Properties to set
         * @returns {rivian.ClientInfo} ClientInfo instance
         */
        ClientInfo.create = function create(properties) {
            return new ClientInfo(properties);
        };

        /**
         * Encodes the specified ClientInfo message. Does not implicitly {@link rivian.ClientInfo.verify|verify} messages.
         * @function encode
         * @memberof rivian.ClientInfo
         * @static
         * @param {rivian.IClientInfo} message ClientInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClientInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mobileInfo != null && Object.hasOwnProperty.call(message, "mobileInfo"))
                $root.rivian.MobileInfo.encode(message.mobileInfo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ClientInfo message, length delimited. Does not implicitly {@link rivian.ClientInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof rivian.ClientInfo
         * @static
         * @param {rivian.IClientInfo} message ClientInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClientInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ClientInfo message from the specified reader or buffer.
         * @function decode
         * @memberof rivian.ClientInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {rivian.ClientInfo} ClientInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClientInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.rivian.ClientInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.mobileInfo = $root.rivian.MobileInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ClientInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof rivian.ClientInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {rivian.ClientInfo} ClientInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClientInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ClientInfo message.
         * @function verify
         * @memberof rivian.ClientInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ClientInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.mobileInfo != null && message.hasOwnProperty("mobileInfo")) {
                properties.content = 1;
                {
                    let error = $root.rivian.MobileInfo.verify(message.mobileInfo);
                    if (error)
                        return "mobileInfo." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ClientInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof rivian.ClientInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {rivian.ClientInfo} ClientInfo
         */
        ClientInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.rivian.ClientInfo)
                return object;
            let message = new $root.rivian.ClientInfo();
            if (object.mobileInfo != null) {
                if (typeof object.mobileInfo !== "object")
                    throw TypeError(".rivian.ClientInfo.mobileInfo: object expected");
                message.mobileInfo = $root.rivian.MobileInfo.fromObject(object.mobileInfo);
            }
            return message;
        };

        /**
         * Creates a plain object from a ClientInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof rivian.ClientInfo
         * @static
         * @param {rivian.ClientInfo} message ClientInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ClientInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.mobileInfo != null && message.hasOwnProperty("mobileInfo")) {
                object.mobileInfo = $root.rivian.MobileInfo.toObject(message.mobileInfo, options);
                if (options.oneofs)
                    object.content = "mobileInfo";
            }
            return object;
        };

        /**
         * Converts this ClientInfo to JSON.
         * @function toJSON
         * @memberof rivian.ClientInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ClientInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ClientInfo
         * @function getTypeUrl
         * @memberof rivian.ClientInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ClientInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/rivian.ClientInfo";
        };

        return ClientInfo;
    })();

    rivian.MobileInfo = (function() {

        /**
         * Properties of a MobileInfo.
         * @memberof rivian
         * @interface IMobileInfo
         * @property {rivian.Model|null} [model] MobileInfo model
         * @property {string|null} [modelRaw] MobileInfo modelRaw
         */

        /**
         * Constructs a new MobileInfo.
         * @memberof rivian
         * @classdesc Represents a MobileInfo.
         * @implements IMobileInfo
         * @constructor
         * @param {rivian.IMobileInfo=} [properties] Properties to set
         */
        function MobileInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MobileInfo model.
         * @member {rivian.Model} model
         * @memberof rivian.MobileInfo
         * @instance
         */
        MobileInfo.prototype.model = 0;

        /**
         * MobileInfo modelRaw.
         * @member {string} modelRaw
         * @memberof rivian.MobileInfo
         * @instance
         */
        MobileInfo.prototype.modelRaw = "";

        /**
         * Creates a new MobileInfo instance using the specified properties.
         * @function create
         * @memberof rivian.MobileInfo
         * @static
         * @param {rivian.IMobileInfo=} [properties] Properties to set
         * @returns {rivian.MobileInfo} MobileInfo instance
         */
        MobileInfo.create = function create(properties) {
            return new MobileInfo(properties);
        };

        /**
         * Encodes the specified MobileInfo message. Does not implicitly {@link rivian.MobileInfo.verify|verify} messages.
         * @function encode
         * @memberof rivian.MobileInfo
         * @static
         * @param {rivian.IMobileInfo} message MobileInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MobileInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.model != null && Object.hasOwnProperty.call(message, "model"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.model);
            if (message.modelRaw != null && Object.hasOwnProperty.call(message, "modelRaw"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.modelRaw);
            return writer;
        };

        /**
         * Encodes the specified MobileInfo message, length delimited. Does not implicitly {@link rivian.MobileInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof rivian.MobileInfo
         * @static
         * @param {rivian.IMobileInfo} message MobileInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MobileInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MobileInfo message from the specified reader or buffer.
         * @function decode
         * @memberof rivian.MobileInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {rivian.MobileInfo} MobileInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MobileInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.rivian.MobileInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.model = reader.int32();
                        break;
                    }
                case 2: {
                        message.modelRaw = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MobileInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof rivian.MobileInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {rivian.MobileInfo} MobileInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MobileInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MobileInfo message.
         * @function verify
         * @memberof rivian.MobileInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MobileInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.model != null && message.hasOwnProperty("model"))
                switch (message.model) {
                default:
                    return "model: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                case 17:
                case 18:
                case 19:
                case 20:
                case 21:
                case 22:
                case 23:
                case 24:
                case 25:
                case 26:
                case 27:
                case 28:
                case 29:
                case 30:
                case 31:
                case 32:
                    break;
                }
            if (message.modelRaw != null && message.hasOwnProperty("modelRaw"))
                if (!$util.isString(message.modelRaw))
                    return "modelRaw: string expected";
            return null;
        };

        /**
         * Creates a MobileInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof rivian.MobileInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {rivian.MobileInfo} MobileInfo
         */
        MobileInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.rivian.MobileInfo)
                return object;
            let message = new $root.rivian.MobileInfo();
            switch (object.model) {
            default:
                if (typeof object.model === "number") {
                    message.model = object.model;
                    break;
                }
                break;
            case "DEFAULT":
            case 0:
                message.model = 0;
                break;
            case "IPHONE_13":
            case 1:
                message.model = 1;
                break;
            case "PIXEL_6":
            case 2:
                message.model = 2;
                break;
            case "IPHONE_13_PRO":
            case 3:
                message.model = 3;
                break;
            case "IPHONE_12_PRO":
            case 4:
                message.model = 4;
                break;
            case "IPHONE_13_PRO_MAX":
            case 5:
                message.model = 5;
                break;
            case "IPHONE_12_PRO_MAX":
            case 6:
                message.model = 6;
                break;
            case "IPHONE_12":
            case 7:
                message.model = 7;
                break;
            case "IPHONE_11":
            case 8:
                message.model = 8;
                break;
            case "IPHONE_11_PRO":
            case 9:
                message.model = 9;
                break;
            case "IPHONE_11_PRO_MAX":
            case 10:
                message.model = 10;
                break;
            case "IPHONE_XR":
            case 11:
                message.model = 11;
                break;
            case "IPHONE_12_MINI":
            case 12:
                message.model = 12;
                break;
            case "IPHONE_13_MINI":
            case 13:
                message.model = 13;
                break;
            case "S22_ULTRA":
            case 14:
                message.model = 14;
                break;
            case "PIXEL_5":
            case 15:
                message.model = 15;
                break;
            case "S21_ULTRA_5G":
            case 16:
                message.model = 16;
                break;
            case "S22_ULTRA_5G":
            case 17:
                message.model = 17;
                break;
            case "S21_5G":
            case 18:
                message.model = 18;
                break;
            case "IPHONE_14":
            case 19:
                message.model = 19;
                break;
            case "IPHONE_14_PLUS":
            case 20:
                message.model = 20;
                break;
            case "IPHONE_14_PRO":
            case 21:
                message.model = 21;
                break;
            case "IPHONE_14_PRO_MAX":
            case 22:
                message.model = 22;
                break;
            case "PIXEL_7":
            case 23:
                message.model = 23;
                break;
            case "PIXEL_6A":
            case 24:
                message.model = 24;
                break;
            case "PIXEL_7_PRO":
            case 25:
                message.model = 25;
                break;
            case "IPHONE_15_AND_15_PLUS":
            case 26:
                message.model = 26;
                break;
            case "IPHONE_15_PRO":
            case 27:
                message.model = 27;
                break;
            case "PIXEL_8":
            case 28:
                message.model = 28;
                break;
            case "PIXEL_8_PRO":
            case 29:
                message.model = 29;
                break;
            case "IPHONE_15_PRO_MAX":
            case 30:
                message.model = 30;
                break;
            case "S23":
            case 31:
                message.model = 31;
                break;
            case "S24":
            case 32:
                message.model = 32;
                break;
            }
            if (object.modelRaw != null)
                message.modelRaw = String(object.modelRaw);
            return message;
        };

        /**
         * Creates a plain object from a MobileInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof rivian.MobileInfo
         * @static
         * @param {rivian.MobileInfo} message MobileInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MobileInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.model = options.enums === String ? "DEFAULT" : 0;
                object.modelRaw = "";
            }
            if (message.model != null && message.hasOwnProperty("model"))
                object.model = options.enums === String ? $root.rivian.Model[message.model] === undefined ? message.model : $root.rivian.Model[message.model] : message.model;
            if (message.modelRaw != null && message.hasOwnProperty("modelRaw"))
                object.modelRaw = message.modelRaw;
            return object;
        };

        /**
         * Converts this MobileInfo to JSON.
         * @function toJSON
         * @memberof rivian.MobileInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MobileInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MobileInfo
         * @function getTypeUrl
         * @memberof rivian.MobileInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MobileInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/rivian.MobileInfo";
        };

        return MobileInfo;
    })();

    /**
     * Model enum.
     * @name rivian.Model
     * @enum {number}
     * @property {number} DEFAULT=0 DEFAULT value
     * @property {number} IPHONE_13=1 IPHONE_13 value
     * @property {number} PIXEL_6=2 PIXEL_6 value
     * @property {number} IPHONE_13_PRO=3 IPHONE_13_PRO value
     * @property {number} IPHONE_12_PRO=4 IPHONE_12_PRO value
     * @property {number} IPHONE_13_PRO_MAX=5 IPHONE_13_PRO_MAX value
     * @property {number} IPHONE_12_PRO_MAX=6 IPHONE_12_PRO_MAX value
     * @property {number} IPHONE_12=7 IPHONE_12 value
     * @property {number} IPHONE_11=8 IPHONE_11 value
     * @property {number} IPHONE_11_PRO=9 IPHONE_11_PRO value
     * @property {number} IPHONE_11_PRO_MAX=10 IPHONE_11_PRO_MAX value
     * @property {number} IPHONE_XR=11 IPHONE_XR value
     * @property {number} IPHONE_12_MINI=12 IPHONE_12_MINI value
     * @property {number} IPHONE_13_MINI=13 IPHONE_13_MINI value
     * @property {number} S22_ULTRA=14 S22_ULTRA value
     * @property {number} PIXEL_5=15 PIXEL_5 value
     * @property {number} S21_ULTRA_5G=16 S21_ULTRA_5G value
     * @property {number} S22_ULTRA_5G=17 S22_ULTRA_5G value
     * @property {number} S21_5G=18 S21_5G value
     * @property {number} IPHONE_14=19 IPHONE_14 value
     * @property {number} IPHONE_14_PLUS=20 IPHONE_14_PLUS value
     * @property {number} IPHONE_14_PRO=21 IPHONE_14_PRO value
     * @property {number} IPHONE_14_PRO_MAX=22 IPHONE_14_PRO_MAX value
     * @property {number} PIXEL_7=23 PIXEL_7 value
     * @property {number} PIXEL_6A=24 PIXEL_6A value
     * @property {number} PIXEL_7_PRO=25 PIXEL_7_PRO value
     * @property {number} IPHONE_15_AND_15_PLUS=26 IPHONE_15_AND_15_PLUS value
     * @property {number} IPHONE_15_PRO=27 IPHONE_15_PRO value
     * @property {number} PIXEL_8=28 PIXEL_8 value
     * @property {number} PIXEL_8_PRO=29 PIXEL_8_PRO value
     * @property {number} IPHONE_15_PRO_MAX=30 IPHONE_15_PRO_MAX value
     * @property {number} S23=31 S23 value
     * @property {number} S24=32 S24 value
     */
    rivian.Model = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "DEFAULT"] = 0;
        values[valuesById[1] = "IPHONE_13"] = 1;
        values[valuesById[2] = "PIXEL_6"] = 2;
        values[valuesById[3] = "IPHONE_13_PRO"] = 3;
        values[valuesById[4] = "IPHONE_12_PRO"] = 4;
        values[valuesById[5] = "IPHONE_13_PRO_MAX"] = 5;
        values[valuesById[6] = "IPHONE_12_PRO_MAX"] = 6;
        values[valuesById[7] = "IPHONE_12"] = 7;
        values[valuesById[8] = "IPHONE_11"] = 8;
        values[valuesById[9] = "IPHONE_11_PRO"] = 9;
        values[valuesById[10] = "IPHONE_11_PRO_MAX"] = 10;
        values[valuesById[11] = "IPHONE_XR"] = 11;
        values[valuesById[12] = "IPHONE_12_MINI"] = 12;
        values[valuesById[13] = "IPHONE_13_MINI"] = 13;
        values[valuesById[14] = "S22_ULTRA"] = 14;
        values[valuesById[15] = "PIXEL_5"] = 15;
        values[valuesById[16] = "S21_ULTRA_5G"] = 16;
        values[valuesById[17] = "S22_ULTRA_5G"] = 17;
        values[valuesById[18] = "S21_5G"] = 18;
        values[valuesById[19] = "IPHONE_14"] = 19;
        values[valuesById[20] = "IPHONE_14_PLUS"] = 20;
        values[valuesById[21] = "IPHONE_14_PRO"] = 21;
        values[valuesById[22] = "IPHONE_14_PRO_MAX"] = 22;
        values[valuesById[23] = "PIXEL_7"] = 23;
        values[valuesById[24] = "PIXEL_6A"] = 24;
        values[valuesById[25] = "PIXEL_7_PRO"] = 25;
        values[valuesById[26] = "IPHONE_15_AND_15_PLUS"] = 26;
        values[valuesById[27] = "IPHONE_15_PRO"] = 27;
        values[valuesById[28] = "PIXEL_8"] = 28;
        values[valuesById[29] = "PIXEL_8_PRO"] = 29;
        values[valuesById[30] = "IPHONE_15_PRO_MAX"] = 30;
        values[valuesById[31] = "S23"] = 31;
        values[valuesById[32] = "S24"] = 32;
        return values;
    })();

    return rivian;
})();

export { $root as default };

import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';

import api from '../../services/api';
import errorHandler from '../../services/sentry';

import { stripTrailingSlashes } from '../../utils';

import Page from '../Page';
import {
  Button,
  Form,
  Header,
  Footer,
  Box,
  ModalWrapper,
  InlineStyledText,
  Paragraph,
  Toast,
  BluetoothVehicles,
} from '../../components';

import { useFlashErrorText } from '../../contexts/FlashErrorText';
import { useAppLoading } from '../../contexts/AppLoading';

import { IPageProps } from '../../interfaces/pages/page.interface';
import { IComponentTree } from '../../interfaces/app.interface';

const componentMap = {
  modalWrapper: ModalWrapper,
  paragraph: Paragraph,
  toast: Toast,
  inlineStyledText: InlineStyledText,
  bluetoothVehicles: BluetoothVehicles,
  button: Button,
  box: Box,
  form: Form,
  header: Header,
  footer: Footer,
};

const navigationMap = {
  '/bluetooth-pairing': {
    componentRoute: '/oauth/after-grant?component=bluetooth-pairing',
  },
};

const AfterGrant = (props: IPageProps) => {
  const {
    componentTree, pageMetadata, setComponentTree,
  } = props;
  const location = useLocation();
  const { resetError } = useFlashErrorText();
  const { setAppLoading } = useAppLoading();

  const fetchData = useCallback(async (loc: { pathname: string }) => {
    // first, clear any flash errors
    resetError();
    // set loading indicator
    setAppLoading(true);

    const cleanedPath = stripTrailingSlashes(loc.pathname) as
      | '/bluetooth-pairing';

    const navigation = navigationMap[cleanedPath];

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data }: { data: IComponentTree } = await api.getComponents(navigation.componentRoute);

    // reset loading indicator
    setAppLoading(false);

    setComponentTree(data);
  }, []);

  useEffect(() => {
    // need to pass the location object here to ensure getComponents
    // uses the updated location pathname
    fetchData(location)
      .catch((e: AxiosError) => {
      // reset loading indicator
        setAppLoading(false);
        // display error info if available
        if (e.response?.data) {
          if (e.response?.data && typeof e.response.data === 'object') {
            setComponentTree(e.response.data as IComponentTree);
          }
        } else {
          errorHandler(e);
        }
      });
  }, [fetchData, location.pathname]);

  return (
    <Page
      componentMap={componentMap}
      componentTree={componentTree}
      pageMetadata={pageMetadata}
      setComponentTree={setComponentTree}
    />
  );
};

export default AfterGrant;

import {
  createContext, useState, useMemo, useContext,
} from 'react';
import { IThemeContext, IProps } from '../interfaces/contexts/theme.interface';

export const ThemeContext = createContext<IThemeContext>({
  theme: 'light', // default value
  updateTheme: () => {}, // placeholder function
});

export const ThemeProvider = ({ children }: IProps) => {
  const [theme, setTheme] = useState<'light' | 'dark'>('light');

  /*
   * keeping updateTheme here as a part of the context to prevent any use of setTheme
   * without updating the html body class
   */
  const updateTheme = (newTheme: 'light' | 'dark') => {
    setTheme(newTheme);
    // second argument will 'force' the class if true
    document.body.classList.toggle('theme--dark', newTheme === 'dark');
  };

  const contextValue = useMemo(() => ({ theme, updateTheme }), [theme]);

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);

  if (context === undefined) {
    throw new Error('useTheme must be used within the ThemeContext provider');
  }

  return context;
};

/**
 * Formats the given icon file path to include a 'dark/' segment if the current theme is dark.
 * The 'dark/' segment is inserted after the last '/' in the path. If the theme is not dark,
 * the unmodified original path is returned.
 * As of Nov 8, 2023, the only path with a dark folder is /icons.
 *
 * @param {string} path - The original icon file path to be formatted.
 * @returns {string} The formatted icon file path according to the current theme.
 */
export const formatIconPath = (path: string, flipped = false): string => {
  const { theme } = useContext(ThemeContext);

  if ((theme === 'dark' && !flipped) || (theme === 'light' && flipped)) {
    const lastSlashIndex = path.lastIndexOf('/');
    if (lastSlashIndex !== -1) {
      return `${path.substring(0, lastSlashIndex + 1)}dark/${path.substring(lastSlashIndex + 1)}`;
    }
  }

  return path;
};

interface IPairedVehicles {
  [key: string]: {
    value: boolean;
  };
}
const rules: { [key: string]: (formState: object) => boolean } = {
  allVehiclesPaired: (formState): boolean => {
    if ((formState as { pairedVehicles?: IPairedVehicles }).pairedVehicles) {
      return Object.values((formState as { pairedVehicles: IPairedVehicles }).pairedVehicles)
        .every((item) => item.value);
    }
    return false;
  },
  noVehiclePaired: (formState): boolean => {
    if ((formState as { pairedVehicles?: IPairedVehicles }).pairedVehicles) {
      return Object.values((formState as { pairedVehicles: IPairedVehicles }).pairedVehicles)
        .every((item) => !item.value);
    }
    return false;
  },
};

// eslint-disable-next-line import/prefer-default-export
export const skipConfirmation = (formState: object, validator: string | undefined): boolean => {
  if (!validator || !rules[validator]) return false;

  return rules[validator](formState);
};

export const disabledByCondition = (formState: object, validator: string | undefined): boolean => {
  if (!validator || !rules[validator]) return false;

  return rules[validator](formState);
};

import clsx from 'clsx';
import {
  IProps, IIcon, IText,
} from '../../interfaces/components/listSection.interface';
import Tooltip from '../Tooltip';
import { formatIconPath } from '../../contexts/Theme';

const ListSection = ({
  title, className, children,
}: IProps) => {
  let titleText: string | React.ReactElement = '';
  if (typeof title === 'string') {
    titleText = title;
  } else if (Array.isArray(title)) {
    titleText = (
      <span className="flex align-start">
        {title.map((section) => {
          if (section.type === 'icon') {
            const iconItem = section as IIcon;
            return (
              <Tooltip
                key={iconItem.icon.alt}
                tooltipContent={iconItem.tooltipContent}
              >
                <span>
                  <img
                    className={iconItem.icon.className}
                    src={formatIconPath(iconItem.icon.url)}
                    alt={iconItem.icon.alt}
                  />
                </span>
              </Tooltip>
            );
          }
          const textItem = section as IText;
          return <span key={textItem.text}>{textItem.text}</span>;
        })}
      </span>
    );
  }

  return (
    <div className={clsx(className)}>
      {title && <h2>{titleText}</h2>}
      {children}
    </div>
  );
};

export default ListSection;

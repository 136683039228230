import { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';

import { IProps } from '../../interfaces/components/toast.interface';
import { FormContext } from '../Form/Form';
import { IFormContext } from '../../interfaces/components/form.interface';
import SvgImage from '../SvgImage';

const Toast = ({
  defaultShow = true,
  text = 'message',
  type,
  className,
  svgImage,
}: IProps) => {
  const [showToast, setShowToast] = useState(defaultShow);
  const context = useContext<IFormContext | undefined>(FormContext);

  useEffect(() => {
    setShowToast(Boolean(
      defaultShow
      || (type === 'showOnSubmit' && (context?.formValidated || context?.formState?.oemCaptchaToken))
    ));
  }, [context?.formValidated, context?.formState?.oemCaptchaToken]);

  return (
    <div
      id="toast-message"
      className={clsx('toast', showToast && 'show-toast', className)}
    >
      {svgImage && <SvgImage {...svgImage} />}
      <p className="toast-text">{text}</p>
    </div>
  );
};

export default Toast;

/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useContext, useEffect, useState } from 'react';
import appConfig from 'appConfig';

import { FormContext } from '../Form/Form';
import { IFormContext } from '../../interfaces/components/form.interface';

import { useModal } from '../../contexts/Modal';
import { IProps } from '../../interfaces/components/bluetoothVehicles.interface';
import pairBluetooth from '../../services/rivian-bluetooth';
import api from '../../services/api';
import { useFlashErrorText } from '../../contexts/FlashErrorText';
import errorHandler from '../../services/sentry';
import { SelectionsContext } from '../../contexts/Selections';
import VehicleCard from '../VehicleCard';

interface IVehiclesForm {
  pairedVehicles: {
    [key: string]: {
      value: boolean;
      error?: string;
    };
  };
}

interface IBluetoothCredentials {
  hmacSecret: string;
  vehicleId: string;
  phoneId: string;
}

const BluetoothVehicles = ({
  statuses,
  errorMap,
  vehicles,
  setComponentTree,
}: IProps) => {
  const context = useContext<IFormContext | undefined>(FormContext);
  const selectionsContext = useContext(SelectionsContext);
  const { view: modalView, openModal, closeModal } = useModal();
  const { setFlashErrorText } = useFlashErrorText();
  const [cancelOperation, setCancelOperation] = useState<() => Promise<void> | null>(() => null);
  const [pairingOperation, setPairingOperation] = useState<() => Promise<void> | null>(() => null);

  const createPairingOperation = (vehicle, credentials) => async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { promise: pairingPromise, cancel } = pairBluetooth(credentials);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      setCancelOperation(() => cancel);
      await pairingPromise;

      context?.setFormState((formState: IVehiclesForm) => ({
        ...formState,
        pairedVehicles: {
          ...formState.pairedVehicles,
          [vehicle.id]: {
            value: true,
          },
        },
      }));
      setFlashErrorText('');
    } catch (err: any) {
      if (err.message !== 'Operation cancelled') {
        errorHandler(err);
        const message = errorMap[err.message] || errorMap.default;
        setFlashErrorText(message);
      }
    } finally {
      closeModal();
      setPairingOperation(() => null);
      setCancelOperation(() => null);
    }
  };

  const createBluetoothHandler = (vehicle) => async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      selectionsContext?.setVehicleToPair(vehicle);

      setFlashErrorText('');
      openModal('setupBluetooth');
      const route = `/vehicle/access-credentials/${vehicle.id}/bluetooth`;
      if (appConfig.timedTokenEnabled) {
        await api.getSessionToken();
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { data }: { data: IBluetoothCredentials } = await api.getComponents(route);
      setPairingOperation(() => createPairingOperation(vehicle, data));
    } catch (err: any) {
      closeModal();
      errorHandler(err);
      if (err.response && err.response.data) {
        if (err.response.data.flash) {
          setFlashErrorText(err.response.data.message);
        } else {
        // display page error
          setComponentTree(err.response.data);
        }
      }
    }
  };

  useEffect(() => {
    if (context?.formState) {
      const initialFormstate = vehicles.reduce(
        (acc, vehicle) => ({
          ...acc,
          [vehicle.id]: {
            value: !vehicle.requiresPairing,
          },
        }),
        {},
      );
      context.setFormState((formState: IVehiclesForm) => ({
        ...formState,
        pairedVehicles: initialFormstate,
      }));
    }

    return closeModal;
  }, []);

  useEffect(() => {
    // Modal closed implies the user cancelled the operation
    if (!modalView && cancelOperation) {
      cancelOperation();
    } else if (modalView === 'connectToBluetooth' && pairingOperation) {
      pairingOperation();
    }
  }, [modalView, pairingOperation]);

  return (context?.formState as IVehiclesForm).pairedVehicles ? vehicles.map((vehicle, i) => {
    const granted = Boolean(
      (context?.formState as IVehiclesForm)?.pairedVehicles[vehicle.id]?.value,
    );
    const chipAdditionaClassNames = granted ? 'granted' : 'required';
    const statusLabel = statuses[granted ? 'granted' : 'required'];
    const lastVehicle = i === vehicles.length - 1;
    return (
      <VehicleCard
        key={vehicle.id}
        disabled={Boolean(granted)}
        vehicle={vehicle}
        statusLabel={statusLabel}
        handleClick={createBluetoothHandler(vehicle)}
        chipAdditionaClassNames={chipAdditionaClassNames}
        additionalClassNames={lastVehicle ? 'm-b-xl' : ''}
      />
    );
  }) : null;
};

export default BluetoothVehicles;

import clsx from 'clsx';

import { IProps } from '../../interfaces/components/inlineStyledText.interface';

const InlineStyledText = ({ text }: IProps) => {
  const textArr = text.split('|*|');
  return (
    <>
      {textArr.map((textChunk) => {
        const textChunkSplit = textChunk.split('@@');

        if (textChunkSplit.length === 1) {
          // need an altnernative solution to key={textChunk}
          // duplicate keys based on text was causing UI issues
          return <span>{textChunk}</span>;
        }
        return (
          // same for key={textChunkSplit[1]}
          <span className={clsx(textChunkSplit[0])}>
            {textChunkSplit[1]}
          </span>
        );
      })}
    </>
  );
};

export default InlineStyledText;

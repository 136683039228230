/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import {
  useCallback, useEffect,
} from 'react';
import { AxiosError } from 'axios';
import { useLocation } from 'react-router-dom';

import api from '../../services/api';
import errorHandler from '../../services/sentry';

import { stripTrailingSlashes } from '../../utils';

import Page from '../Page';
import {
  Button,
  CopyText,
  Form,
  Header,
  Image,
  Paragraph,
  Footer,
  Link,
  Box,
} from '../../components';

import { IPageProps } from '../../interfaces/pages/page.interface';
import { useAppLoading } from '../../contexts/AppLoading';

const componentMap = {
  button: Button,
  copyText: CopyText,
  form: Form,
  header: Header,
  image: Image,
  paragraph: Paragraph,
  footer: Footer,
  link: Link,
  box: Box,
};

const navigationMap = {
  '/reauthenticate/success': {
    componentRoute: '/reauth/success',
  },
  '/battery-capacity/select-success': {
    componentRoute: '/battery-capacity/select-success',
  },
};

const Success = (props: IPageProps) => {
  const {
    componentTree, pageMetadata, setComponentTree,
  } = props;
  const location = useLocation();

  const { setAppLoading } = useAppLoading();

  const fetchData = useCallback(async (loc) => {
    // set loading indicator
    setAppLoading(true);

    const cleanedPath = stripTrailingSlashes(loc.pathname) as
      | '/reauthenticate/success'
      | '/battery-capacity/success';
    const navigationRoute = navigationMap[cleanedPath].componentRoute;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data } = await api.getComponents(navigationRoute);

    // reset loading indicator
    setAppLoading(false);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setComponentTree(data);
  }, []);

  useEffect(() => {
    fetchData(location)
      // will send to sentry once it's set up
      .catch((e: AxiosError) => {
        // reset loading indicator
        setAppLoading(false);
        // display error info if available
        if (e.response?.data) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setComponentTree(e.response.data);
        } else {
          errorHandler(e);
        }
      });
  }, [fetchData]);

  return (
    <Page
      componentMap={componentMap}
      componentTree={componentTree}
      pageMetadata={pageMetadata}
      setComponentTree={setComponentTree}
    />
  );
};

export default Success;

import clsx from 'clsx';
import { useContext } from 'react';

import { IProps } from '../../interfaces/components/inlineStyledText.interface';
import { SelectionsContext } from '../../contexts/Selections';

const InlineStyledText = ({ text: rawText, placeholders }: IProps) => {
  let text = rawText;
  const context = useContext(SelectionsContext);

  const getPlaceholderValue = (placeholder: string) => {
    if (placeholder === '{{feVehicleNickname}}' && context && context.vehicleToPair) {
      return context.vehicleToPair.nickname;
    }
    return placeholder;
  };

  if (placeholders) {
    placeholders.forEach((placeholder) => {
      text = text.replaceAll(placeholder, getPlaceholderValue(placeholder));
    });
  }
  const textArr = text.split('|*|');
  return (
    <>
      {textArr.map((textChunk) => {
        const textChunkSplit = textChunk.split('@@');

        if (textChunkSplit.length === 1) {
          // need an altnernative solution to key={textChunk}
          // duplicate keys based on text was causing UI issues
          return <span>{textChunk}</span>;
        }
        return (
          // same for key={textChunkSplit[1]}
          <span className={clsx(textChunkSplit[0])}>
            {textChunkSplit[1]}
          </span>
        );
      })}
    </>
  );
};

export default InlineStyledText;

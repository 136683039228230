import clsx from 'clsx';
import { IProps } from '../../interfaces/components/stepperBar.interface';

const StepperBar = ({ currentStepIndex, totalSteps }: IProps) => {
  // total gap in px divided by number of steps
  const stepGapPx = (12 * (totalSteps - 1)) / totalSteps;
  return (
    <div className="stepper-bar">
      {Array(totalSteps).fill(0).map((_, i) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          style={{ width: `calc(${100 / totalSteps}% - ${stepGapPx}px)` }}
          className={clsx('step', i <= currentStepIndex && 'active')}
        />
      ))}
    </div>
  );
};

export default StepperBar;

import clsx from 'clsx';

import FlashError from '../FlashError';
import Tooltip from '../Tooltip';

import { useFlashErrorText } from '../../contexts/FlashErrorText';
import { useAppLoading } from '../../contexts/AppLoading';
import { formatIconPath } from '../../contexts/Theme';

import { IProps } from '../../interfaces/components/header.interface';
import InlineStyledText from '../InlineStyledText';

const Header = ({
  children, className, error, image, title,
}: IProps) => {
  const { loginPageLoading } = useAppLoading();
  const { flashErrorText, metadataText } = useFlashErrorText() as {
    flashErrorText: string, metadataText?: string
  };

  let errorText = '';
  let errorMetadataText = '' as string | undefined;
  const hasError = error || flashErrorText;
  if (hasError) {
    errorText = error?.errorText || flashErrorText;
    errorMetadataText = error?.metadataText || metadataText;
  }

  let headerText: string | React.ReactElement = '';
  if (typeof title.text === 'string') {
    headerText = <InlineStyledText text={title.text} />;
  } else if (Array.isArray(title.text)) {
    headerText = (
      <span>
        {title.text.map((section) => {
          if (section.tooltipContent) {
            return (
              <Tooltip
                key={section.text}
                tooltipContent={section.tooltipContent}
                wrapperClassName={clsx(section.wrapperClassName)}
              >
                <span className={clsx(section.className)}>{section.text}</span>
              </Tooltip>
            );
          }
          return <InlineStyledText key={section.text} text={section.text} />;
        })}
      </span>
    );
  }

  return (
    <header className={clsx('header', className)}>
      {image
        && (
          <img
            src={image.noFormattedPath ? image.url : formatIconPath(image.url)}
            alt={image.alt}
            className={clsx('header-image', image.className, flashErrorText && 'header-alert-on')}
          />
        )}
      <h1 className={title.className}>{headerText}</h1>
      {children || null}
      {!loginPageLoading && hasError && (
        <FlashError
          text={errorText}
          metadataText={errorMetadataText}
        />
      )}
    </header>
  );
};

export default Header;

import { bleReliableFlow } from "./ble.js";
import {
  SERVICE_UUID as LEGACY_SERVICE_UUID,
  authorize as authorizeLegacy,
} from "./rivianLegacy.js";
import { authorize as authorize2025 } from "./rivian2025.js";


/**
 * Starts Rivian authorization flow. Scans for and connects to
 * the vehicle, then chooses either legacy or 2025+ protocol
 * based on discovered GATT services.
 */
const pairBluetooth = ({
  hmacSecret,
  vehicleId,
  phoneId,
}) => {
  if (!hmacSecret || !vehicleId || !phoneId) {
    throw new Error('Missing access credentials');
  }

  // console.log('Starting Rivian pairing flow...', vehicleId);

  // Scanning for vehicle
  return bleReliableFlow({
    // Find the primary/key device, rather than a sensor
    scanFilters: [
      { // Filter for new (2025+) Rivians
        services: [vehicleId],
        manufacturerData: [{
          companyIdentifier: 0x941, // Rivian manufacturer ID
          // Expect first byte bits 7 and 6 to be set
          dataPrefix: Buffer.from([0xC0]),
          mask: Buffer.from([0xC0]),
        }]
      },
      { // Filter for legacy (pre-2025) Rivians
        services: [vehicleId],
        manufacturerData: [{
          companyIdentifier: 0x941, // Rivian manufacturer ID
          // For legacy, we require that:
          // - Byte[0] has bit 7 cleared (mask 0x80, expected 0x00)
          // - Byte[1] equals 1 (mask 0xFF, expected 0x01)
          dataPrefix: Buffer.from([0x00, 0x01]),
          mask: Buffer.from([0x80, 0xFF]),
        }]
      }
    ],
    gattFlow: async (address, gatt) => {
      // Found vehicle, connecting...
      if (gatt.services.some(s => s.uuid.toLowerCase() === LEGACY_SERVICE_UUID.toLowerCase())) {
        // console.log('Legacy Rivian service');
        await authorizeLegacy(address, hmacSecret, vehicleId, phoneId);
      } else {
        // console.log('2025+ Rivian service');
        await authorize2025(address, hmacSecret, vehicleId, phoneId);
      }
    }
  });
};

export default pairBluetooth;

export const unallowedBrandLogos = ['ACURA', 'AUDI', 'HONDA', 'VOLKSWAGEN'];

export const deepClone = (items) => items.map((item) => (
  Array.isArray(item) ? deepClone(item) : item
));

export const stripTrailingSlashes = (pathname: string) => {
  let cleanedPath = pathname;
  // strip any trailing slashes
  if (cleanedPath.endsWith('/')) {
    cleanedPath = cleanedPath.slice(0, cleanedPath.length - 1);
  }

  return cleanedPath;
};

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';

import api from '../../services/api';
import errorHandler from '../../services/sentry';

import Page from '../Page';
import {
  Button,
  Form,
  Header,
  Image,
  InlineStyledText,
  Link,
  ListItem,
  OrderedList,
  Paragraph,
  Footer,
  Box,
} from '../../components';

import { useFlashErrorText } from '../../contexts/FlashErrorText';
import { useAppLoading } from '../../contexts/AppLoading';

import { IPageProps } from '../../interfaces/pages/page.interface';

const componentMap = {
  button: Button,
  form: Form,
  header: Header,
  image: Image,
  inlineStyledText: InlineStyledText,
  link: Link,
  listItem: ListItem,
  orderedList: OrderedList,
  paragraph: Paragraph,
  footer: Footer,
  box: Box,
};

const RevokeOemAccess = (props: IPageProps) => {
  const {
    componentTree, pageMetadata, setComponentTree, isPreview,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { resetError } = useFlashErrorText();
  const { setAppLoading } = useAppLoading();

  const fetchData = useCallback(async () => {
    // first, clear any flash errors
    resetError();
    // set loading indicator
    setAppLoading(true);

    let navigation: string;
    if (location.pathname === '/revoke') {
      navigation = '/oauth/revoke';
    } else if (location.pathname === '/update') {
      navigation = '/oauth/update';
    } else {
      navigation = '/reauth/revoke';
    }

    const navigationRoute = isPreview
      ? `${navigation}${location.search}`
      : navigation;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data } = await api.getComponents(navigationRoute);

    // reset loading indicator
    setAppLoading(false);

    if (data.routes) {
      navigate(data.routes.frontend);
    } else {
      setComponentTree(data);
    }
  }, []);

  useEffect(() => {
    // need to pass the location object here to ensure getComponents
    // uses the updated location pathname
    fetchData()
      .catch((e: AxiosError) => {
      // reset loading indicator
        setAppLoading(false);
        // display error info if available
        if (e.response?.data) {
          setComponentTree(e.response.data);
        } else {
          errorHandler(e);
        }
      });
  }, [fetchData, location.pathname]);

  return (
    <Page
      componentMap={componentMap}
      componentTree={componentTree}
      pageMetadata={pageMetadata}
      setComponentTree={setComponentTree}
    />
  );
};

export default RevokeOemAccess;

/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

import api from '../../services/api';
import errorHandler from '../../services/sentry';
import defaultError from '../../payloads/default-error';

import Page from '../Page';
import {
  Box,
  Button,
  CopyText,
  CustomBulletListItem,
  Form,
  Header,
  InlineLinks,
  List,
  Link,
  Paragraph,
  Select,
  Footer,
  PrivacyPolicyModal,
  InlineStyledText,
  ListSection,
} from '../../components';

import { IPreamblePageProps } from '../../interfaces/pages/page.interface';
import { ISelectionsContext } from '../../interfaces/contexts/selections.interface';
import { SelectionsContext } from '../../contexts/Selections';
import { IComponentTree } from '../../interfaces/app.interface';

const componentMap = {
  box: Box,
  button: Button,
  copyText: CopyText,
  customBulletListItem: CustomBulletListItem,
  form: Form,
  header: Header,
  inlineLinks: InlineLinks,
  inlineStyledText: InlineStyledText,
  listSection: ListSection,
  link: Link,
  list: List,
  paragraph: Paragraph,
  privacyPolicyModal: PrivacyPolicyModal,
  select: Select,
  footer: Footer,
};

const Preamble = (props: IPreamblePageProps) => {
  const {
    componentTree,
    pageMetadata,
    setComponentTree,
    isPreview,
    preambleData,
    setPreambleData,
  } = props;

  const navigate = useNavigate();
  const { search } = useLocation();

  const context = useContext<ISelectionsContext | undefined>(SelectionsContext);
  const [fetchedData, setFetchedData] = useState<IComponentTree>();

  const buildComponentTree = (data: IComponentTree | undefined) => {
    if (data) {
      const tree:IComponentTree = {
        metadata: data.metadata,
        components: data.metadata.translations[context?.language?.value || 'en-US'],
      };
      setComponentTree(tree);
    }
  };

  const fetchData = useCallback(async () => {
    const { data } = await api.getComponents(isPreview ? `preview/preamble${search}` : 'oauth/preamble');

    if (data.routes) {
      navigate(data.routes.frontend);
    } else {
      context?.setLanguage({ value: data.metadata.selectedLanguage });
      context?.setCountry({ value: data.metadata.selectedCountry });
      setFetchedData(data);
    }
  }, []);

  useEffect(() => {
    buildComponentTree(fetchedData);
  }, [fetchedData, context?.language.value]);

  useEffect(() => {
    if (preambleData && setPreambleData) {
      setFetchedData(preambleData);
      setPreambleData(undefined);
      if (api.needSessionToken()) {
        api.getSessionToken()
          .catch((e: AxiosError) => {
            errorHandler(e);
            if (e.response?.data?.metadata && e.response?.data?.components) {
              return setComponentTree(e.response.data);
            }
            return setComponentTree(defaultError(e.response?.status));
          });
      }
    } else {
      fetchData()
        .catch((e: AxiosError) => {
          // display error info if available
          if (e.response?.data) {
            setComponentTree(e.response.data);
          } else {
            errorHandler(e);
          }
        });
    }
  }, [fetchData]);

  return (
    <Page
      componentMap={componentMap}
      componentTree={componentTree}
      pageMetadata={pageMetadata}
      setComponentTree={setComponentTree}
    />
  );
};

export default Preamble;

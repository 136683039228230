import {
  useContext, useEffect, useRef, useState,
} from 'react';
import clsx from 'clsx';

import { FormContext } from '../Form/Form';
import { useAppLoading } from '../../contexts/AppLoading';

import { IProps } from '../../interfaces/components/button.interface';
import { IFormContext } from '../../interfaces/components/form.interface';
import External from '../../assets/images/icons/launch.svg?component';
import { formatIconPath } from '../../contexts/Theme';
import { disabledByCondition } from '../Form/utils';
import { ModalContext } from '../../contexts/Modal';

const getIsLoading = (context: IFormContext | undefined, appLoading) => {
  return Boolean((context && context.formValidated) || appLoading);
};

const Button = ({
  additionalClassNames,
  elementAttributes,
  handleClick,
  loadingAnimation,
  text,
  children,
  disabled,
  externalIcon,
  icon,
  delayAttributes,
  action,
}: IProps) => {
  const {
    id, type, value, form,
  } = elementAttributes;
  const progressBar = useRef<HTMLSpanElement>(null);
  const progressText = useRef<HTMLSpanElement>(null);

  const context = useContext<IFormContext | undefined>(FormContext);
  const modalContext = useContext(ModalContext);
  const { appLoading, loginPageLoading } = useAppLoading();
  const [delay, setDelay] = useState(delayAttributes?.valueInSeconds || 0);

  const actionMap: Record<string, () => void> = {
    closeModal: () => {
      if (modalContext) {
        modalContext.closeModal();
      }
    },
    advanceToConnectToBluetooth: () => {
      if (modalContext) {
        modalContext.openModal('connectToBluetooth');
      }
    },
  };

  const getButtonContent = () => {
    if (!disabled && delayAttributes && delay > 0) {
      return delayAttributes.text.replaceAll('{{feDelay}}', delay.toString());
    }
    if (icon) {
      // icon buttons don't need loading content
      return (
        <img
          src={icon.noFormattedPath ? icon.url : formatIconPath(icon.url)}
          alt={icon.alt}
        />
      );
    }
    if (loginPageLoading && loadingAnimation) {
      return (
        <>
          <span
            className="button-progress-bar"
            style={{
              width: '0%',
              transition: `width ${loadingAnimation.linearDelay}s linear`,
            }}
            ref={progressBar}
          />
          <span className="button-loading-text" ref={progressText}>{text}</span>
          <span className="dot">...</span>
        </>
      );
    }
    if (getIsLoading(context, appLoading)) {
      if (!additionalClassNames || (additionalClassNames && !additionalClassNames.includes('link'))) {
        return <span className="spinner" />;
      }
    }
    return text;
  };

  useEffect(() => {
    if (delayAttributes) {
      const timer = setInterval(() => {
        if (delay <= 0) {
          clearInterval(timer);
        } else {
          setDelay((prev) => Math.max(prev - 1, 0));
        }
      }, 1000);
      return () => clearInterval(timer);
    }
    return undefined;
  }, []);

  useEffect(() => {
    if (loginPageLoading && loadingAnimation) {
      const { linearDelay, messages } = loadingAnimation;

      const setFrame = (i: number) => {
        const numOfSteps = messages.length;
        // determine the % of the button width the background will fill to for a single step
        const nextFrameProgress = `${((i + 1) * 100) / numOfSteps}%`;
        // setTimeout for width update, so that the loading bar starts with width 0%
        setTimeout(() => {
          if (progressBar && progressBar.current) {
            progressBar.current.style.width = nextFrameProgress;
          }
        }, 10);
        if (progressText && progressText.current) {
          progressText.current.innerHTML = messages[i];
        }
        // exponential delay in the last step in the span of one minute
        // last step's animation is only concerning the loading bar and
        // thus is fully controlled by css
        if (i === numOfSteps - 1) {
          if (progressBar && progressBar.current) {
            progressBar.current.style.transition = 'width 60s cubic-bezier(.1,.85,.1,1)';
          }
        } else {
          // eslint-disable-next-line no-param-reassign, no-plusplus
          setTimeout(() => setFrame(++i), linearDelay * 1000);
        }
      };

      setFrame(0);
    }
  }, [loginPageLoading]);

  return (
    <button
      id={id}
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={clsx('button', additionalClassNames)}
      value={value}
      form={form}
      onClick={(handleClick && handleClick) || (action && actionMap[action]) || undefined}
      disabled={
        disabled
        || disabledByCondition(context?.formState as object, elementAttributes.disableCondition)
        || getIsLoading(context, appLoading)
        || loginPageLoading
        || delay > 0
      }
    >
      {getButtonContent()}
      {children}
      {externalIcon && (
        <External
          className="svg-fill-only m-l-xs"
          style={{ marginBottom: '2px' }}
        />
      )}
    </button>
  );
};

export default Button;

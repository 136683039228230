/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';

import { FormContext } from '../Form/Form';
import { formatIconPath } from '../../contexts/Theme';

import { IFormContext } from '../../interfaces/components/form.interface';
import { IProps } from '../../interfaces/components/textInput.interface';
import SvgImage from '../SvgImage';

const ruleSet = {
  emailOrTel: {
    pattern: '.+@.+|^[^A-Za-z@]*$',
  },
};

const TextInput = (props: IProps) => {
  const {
    icon,
    id,
    handleChange,
    name,
    placeholder,
    inputClassName,
    required,
    inputType,
    value,
    wrapperClassName,
    validationRule,
    ruleTip,
    additionalInputProps,
    handleKeyDown,
    disabled,
    svgImage,
  } = props;
  const context = useContext<IFormContext | undefined>(FormContext);
  const isPassword = name === 'password';
  const [useInputType, setInputType] = useState(inputType);

  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (context) {
      context.setFormState({
        ...context.formState,
        [name]: {
          ...context.formState[name],
          value: e.target.value,
        },
      });
    } else if (handleChange) {
      handleChange(e.target.value);
    }
  };
  const validationPattern = validationRule && ruleSet[validationRule]?.pattern;

  const togglePasswordVisibility = (isChecked: boolean) => {
    if (isPassword) {
      setInputType(isChecked ? 'text' : 'password');
    }
  };

  useEffect(() => {
    if (context && context.formState['show-password']) {
      const isChecked = context.formState['show-password'].value;
      togglePasswordVisibility(isChecked);
    }
  }, [context?.formState['show-password']]);

  return (
    <div
      className={clsx('input-text-container', wrapperClassName)}
    >
      {/* Can phase out the icon in favor of svgImage */}
      {icon && <img src={formatIconPath(icon)} alt={name} className="input-text-image" />}
      {svgImage && <SvgImage {...svgImage} />}
      <label className="visually-hidden" htmlFor={id}>
        {placeholder}
      </label>
      <input
        className={clsx('input-text-field', inputClassName)}
        id={id}
        name={name}
        onChange={inputChange}
        placeholder={placeholder}
        required={required}
        spellCheck={false}
        type={useInputType}
        value={context?.formState[name].value || value || ''}
        pattern={validationPattern}
        title={validationPattern && ruleTip}
        {...additionalInputProps}
        onKeyDown={handleKeyDown}
        disabled={disabled}
      />
    </div>
  );
};

export default TextInput;

import clsx from 'clsx';

import {
  Account,
  Bluetooth,
  Car,
  Info,
  Lock,
  OutlinedCheck,
  Pending,
  Smartcar,
} from '../../assets/images/icons/dynamic';

import { IImageMap, IProps, IPendingDotsProps } from '../../interfaces/components/svgImage.interface';

const PendingDots = ({ className = '' } : IPendingDotsProps) => {
  const animationAttributes = {
    attributeName: 'transform',
    type: 'translate',
    values: '0 0; 0 -6; 0 0; 0 0',
    keyTimes: '0; 0.4; 0.7; 1',
    keySplines: '0.25 0.1 0.25 1; 0.25 0.1 0.25 1; 0.25 0.1 0.25 1',
    calcMode: 'spline',
    dur: '1',
    repeatCount: 'indefinite',
  };
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
      <circle cx="3" cy="15" r="3" className={className} fill="blue">
        <animateTransform
          begin="0s"
          {...animationAttributes}
        />
      </circle>
      <circle cx="12" cy="15" r="3" className={className} fill="blue">
        <animateTransform
          begin="0.3s"
          {...animationAttributes}
        />
      </circle>
      <circle cx="21" cy="15" r="3" className={className} fill="blue">
        <animateTransform
          begin="0.6s"
          {...animationAttributes}
        />
      </circle>
    </svg>
  );
};

const imageMap: IImageMap = {
  bluetooth: Bluetooth,
  pendingDots: PendingDots,
  car: Car,
  default: Smartcar,
  account: Account,
  lock: Lock,
  outlinedCheck: OutlinedCheck,
  pending: Pending,
  info: Info,
};

const getSvgImage = (name: string) => {
  return imageMap[name] || imageMap.default;
};

const SvgImage = ({
  name, className, height, width, alt, containerClassNames,
}: IProps) => {
  const Icon = getSvgImage(name);
  return (
    <div
      className={clsx('svg-image-container', containerClassNames)}
      style={{
        height, width, display: 'flex',
      }}
    >
      <Icon className={clsx('svg-image', className)} aria-label={alt} role="img" />
    </div>
  );
};

export default SvgImage;

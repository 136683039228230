import { createContext, useMemo, useState } from 'react';
import { ISelectionsContext, IProps } from '../interfaces/contexts/selections.interface';
import { IVehicle } from '../interfaces/components/vehicleCard.interface';

export const SelectionsContext = createContext<ISelectionsContext | undefined>(undefined);

export const SelectionsContextProvider = ({ children }: IProps) => {
  const [language, setLanguage] = useState({ value: 'en-US' });
  const [country, setCountry] = useState({ value: 'US' });
  const [vehicleToPair, setVehicleToPair] = useState<IVehicle | null>(null);

  const contextValue = useMemo(() => ({
    language,
    setLanguage,
    country,
    setCountry,
    vehicleToPair,
    setVehicleToPair,
  }), [language.value, country.value, vehicleToPair]);

  return (
    <SelectionsContext.Provider value={contextValue}>
      {children}
    </SelectionsContext.Provider>
  );
};

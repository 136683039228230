import errorHandler from "../sentry";

// Flags and counters
let rpcId = 1; // Increment for each JSON-RPC request
let scanInProgress = false;

// A simple map to store our in-flight requests, keyed by ID
const pendingRequests = {};

/**
 * Sends a JSON-RPC request and returns a promise that resolves or rejects
 * based on the correlated response from the native layer.
 *
 * @param {string} method - JSON-RPC method
 * @param {object} params - JSON-RPC params (should already be hex strings if sending binary)
 * @returns {Promise<any>} - Resolves with `result` or rejects with `error`
 */
function sendJsonRpcRequest(method, params = {}) {
  return new Promise((resolve, reject) => {
    const id = rpcId++;
    const requestObj = {
      jsonrpc: "2.0",
      method,
      params,
      id,
    };
    const requestStr = JSON.stringify(requestObj);

    // Store the pending request so we can match the response by id
    pendingRequests[id] = { resolve, reject };

    // Send the request to the native layer
    window.SmartcarSDKBLE.sendMessage(requestStr);
  });
}

/**
 * Connect to a GATT server on the BLE device.
 * @param {string} address - The BLE device address
 * @returns {Promise<any>} - Resolves with the result of the connection
 */
function connectGATT(address) {
  return sendJsonRpcRequest("connectGATT", { address });
}

/**
 * Disconnect from a GATT server on the BLE device.
 * @param {string} address - The BLE device address
 * @returns {Promise<any>} - Resolves with the result of the disconnection
 */
function disconnectGATT(address) {
  return sendJsonRpcRequest("disconnectGATT", { address });
}

/**
 * Starts a characteristic notification
 */
async function startNotifications(address, serviceUUID, characteristicUUID) {
  const result = await sendJsonRpcRequest("startNotifications", {
    address,
    serviceUUID,
    characteristicUUID,
  });
}

/**
 * Stops a characteristic notification
 */
async function stopNotifications(address, serviceUUID, characteristicUUID) {
  const result = await sendJsonRpcRequest("stopNotifications", {
    address,
    serviceUUID,
    characteristicUUID,
  });
}

/**
 * Reads a characteristic after connecting, returning the data as a Buffer.
 * Internally, the JSON-RPC response has a hex string in `result.value`.
 */
async function readCharacteristic(address, serviceUUID, characteristicUUID) {
  const result = await sendJsonRpcRequest("readCharacteristic", {
    address,
    serviceUUID,
    characteristicUUID,
  });
  // The native layer returns hex-encoded data as `result.value`.
  // Convert it to a Buffer for external code usage.
  const hexValue = result.value || "";
  const buffer = Buffer.from(hexValue, "hex");
  return buffer;
}

/**
 * Writes a value to a characteristic after connecting.
 * Expects `value` to be a Buffer; we convert it to a hex string for JSON-RPC.
 *
 * @param {string} address
 * @param {string} serviceUUID
 * @param {string} characteristicUUID
 * @param {Buffer} value
 * @returns {Promise<any>} - Resolves with the result of the write operation
 */
async function writeCharacteristic(address, serviceUUID, characteristicUUID, value) {
  if (value instanceof Uint8Array) {
    value = Buffer.from(value);
  }
  if (!(value instanceof Buffer)) {
    throw new Error("writeCharacteristic expected 'value' to be a Buffer");
  }
  // Convert to hex for JSON-RPC
  const hexValue = value.toString("hex");

  const result = await sendJsonRpcRequest("writeCharacteristic", {
    address,
    serviceUUID,
    characteristicUUID,
    value: hexValue,
  });

  return result;
}

/**
 * Starts a BLE scan by sending "startScan" JSON-RPC
 */
async function startScan() {
  if (scanInProgress) {
    // Scan already in progress
    return;
  }
  try {
    await sendJsonRpcRequest("startScan", {});
    scanInProgress = true;
  } catch (err) {
    console.error("Failed to start scan:", err);
  }
}

/**
 * Stops the BLE scan by sending "stopScan" JSON-RPC
 */
async function stopScan() {
  if (!scanInProgress) {
  // stopScan called, but no scan is in progress
    return;
  }
  try {
    await sendJsonRpcRequest("stopScan", {});
    scanInProgress = false;
  } catch (err) {
    console.error("Failed to stop scan:", err);
  }
}

/**
 * Handle the "SmartcarSDKBLEResponse" event, resolving or rejecting any associated
 * pending promise by request ID. Also logs unhandled events with no ID.
 */
addEventListener("SmartcarSDKBLEResponse", (event) => {
  // console.log("Received BLE response:", event.detail);
  const { method, params, id, result, error } = event.detail;

  // If we have an 'id', it's a response to one of our requests
  if (typeof id !== "undefined") {
    const pending = pendingRequests[id];
    if (!pending) {
      console.warn(
        `No pending request found for id=${id}. Possibly already handled or unknown id.`
      );
      return;
    }
    // Remove from pending
    delete pendingRequests[id];

    if (error) {
      pending.reject(error);
    } else {
      pending.resolve(result);
    }
  }
});

/**
 * Waits for a characteristic notification and returns the notified value as a Buffer.
 *
 * @param {string} address - The BLE device address
 * @param {string} serviceUUID - The UUID of the service
 * @param {string} characteristicUUID - The UUID of the characteristic
 * @param {number} [timeout=5000]
 * @returns {Promise<Buffer>}
 */
function nextNotification(address, serviceUUID, characteristicUUID, timeout = 5000) {
  return new Promise(async (resolve, reject) => {
    let timeoutId;

    // Handler for "SmartcarSDKBLEResponse" events
    async function onSmartcarResponse(event) {
      const { detail } = event;
      if (!detail) return;

      // Check for the 'notify' method and ensure it matches our target characteristic
      if (
        detail.method === "notify" &&
        detail.params?.address === address &&
        detail.params?.serviceUUID === serviceUUID &&
        detail.params?.characteristicUUID === characteristicUUID
      ) {
        // The notified value is hex-encoded
        const hexValue = detail.params.value || "";
        const bufferValue = Buffer.from(hexValue, "hex");
        cleanup(bufferValue);
      }
    }

    // Cleans up resources: remove event listener, resolve or reject
    async function cleanup(value, error) {
      clearTimeout(timeoutId);
      removeEventListener("SmartcarSDKBLEResponse", onSmartcarResponse);

      if (error) {
        reject(error);
      } else {
        resolve(value);
      }
    }

    addEventListener("SmartcarSDKBLEResponse", onSmartcarResponse);

    // Set a timeout to reject if no notification arrives in time
    timeoutId = setTimeout(() => {
      cleanup(null, new Error(`Timed out waiting for notification (>${timeout}ms)`));
    }, timeout);
  });
}

/**
 * Checks if a discovered device matches a single filter
 */
function matchesFilter(device, filter) {
  // 1) Check exact name
  if (filter.name && device.name !== filter.name) {
    return false;
  }
  // 2) Check namePrefix
  if (
    filter.namePrefix &&
    !(device.name && device.name.startsWith(filter.namePrefix))
  ) {
    return false;
  }
  // 3) Check services
  if (
    filter.services &&
    Array.isArray(filter.services) &&
    filter.services.length > 0
  ) {
    const deviceServices = device.advertisedServiceUUIDs || [];
    const hasAllServices = filter.services.every((svc) =>
      deviceServices.includes(svc)
    );
    if (!hasAllServices) {
      return false;
    }
  }
  // 4) Check manufacturerData with optional dataPrefix and mask
  if (
    filter.manufacturerData &&
    Array.isArray(filter.manufacturerData) &&
    filter.manufacturerData.length > 0
  ) {
    if (!device.manufacturerData) {
      return false;
    }
    const anyMatch = filter.manufacturerData.some((m) => {
      // Retrieve the manufacturer data for the given company identifier
      const manufacturerHex = device.manufacturerData[m.companyIdentifier];
      if (!manufacturerHex) {
        return false;
      }
      // Convert the hex string to a Buffer for comparison
      const deviceDataBuffer = Buffer.from(manufacturerHex, "hex");

      // If a dataPrefix is specified, compare the beginning of the manufacturer data.
      if (m.dataPrefix) {
        const prefixBuffer = m.dataPrefix; // expected to be a Buffer
        // Ensure the device data is long enough for comparison
        if (deviceDataBuffer.length < prefixBuffer.length) {
          return false;
        }
        // If a mask is provided, use it to selectively compare bytes.
        if (m.mask) {
          const maskBuffer = m.mask; // expected to be a Buffer
          // The prefix and mask must be of the same length.
          if (prefixBuffer.length !== maskBuffer.length) {
            return false;
          }
          for (let i = 0; i < prefixBuffer.length; i++) {
            if (
              (deviceDataBuffer[i] & maskBuffer[i]) !==
              (prefixBuffer[i] & maskBuffer[i])
            ) {
              return false;
            }
          }
          return true;
        } else {
          // Without a mask, perform a direct prefix comparison.
          return Buffer.compare(
            prefixBuffer,
            deviceDataBuffer.subarray(0, prefixBuffer.length)
          ) === 0;
        }
      }
      // If no dataPrefix is provided, matching the presence of the companyIdentifier is enough.
      return true;
    });
    if (!anyMatch) {
      return false;
    }
  }
  // 5) Check serviceData
  if (
    filter.serviceData &&
    Array.isArray(filter.serviceData) &&
    filter.serviceData.length > 0
  ) {
    if (!device.serviceData) {
      return false;
    }
    // device.serviceData is { [serviceUUID]: <hexString> }
    const anyMatch = filter.serviceData.some(
      (s) => device.serviceData[s.service]
    );
    if (!anyMatch) {
      return false;
    }
  }
  // If all checks pass, it's a match
  return true;
}

/**
 * Manages reliable BLE device connection and operation flow with automatic retries.
 *
 * This function:
 * 1. Starts a BLE scan using the provided filters
 * 2. Connects to matching devices one at a time
 * 3. Executes the provided gattFlow function with the connected device
 * 4. If gattFlow fails, disconnects and retries with the next matching device
 * 5. If gattFlow succeeds, disconnects and returns the result
 * 6. Stops scanning when the operation completes or is cancelled
 *
 * @param {Object} options - Configuration options
 * @param {Array} options.scanFilters - Filters for device scanning (same format as requestDevice)
 * @param {Function} options.gattFlow - Async function that receives (deviceAddress, connectionResult)
 *                                     and performs GATT operations
 * @returns {Object} result - Result object
 * @returns {Promise<any>} result.promise - Resolves with the gattFlow result or rejects if cancelled
 * @returns {Function} result.cancel - Call to cancel the operation and disconnect any active device
 *
 * @note Only one device is connected at a time. Device advertisements are ignored while connected.
 * @note Scanning continues throughout the operation and only stops when the function completes or is cancelled.
 * @note When cancelled, any connected device is automatically disconnected.
 */
function bleReliableFlow({
  scanFilters = [],
  gattFlow, /* async function that accepts the result from connectGATT */
}) {
  let currentDevice = null;
  let cancel;

  // Create a cancellation promise that will reject when cancel() is called.
  const cancellationPromise = new Promise((_, reject) => {
    // Cancellation handle: triggers the cancellation promise and disconnects any connected device.
    cancel = function() {
      // console.log("Cancelling operation");
      errorHandler('Operation cancelled');
      reject(new Error("Operation cancelled"));
      if (currentDevice && currentDevice.address) {
        disconnectGATT(currentDevice.address).catch((err) => {
          console.error("Error disconnecting during cancellation:", err);
        });
      }
    }
  });

  // Helper function that waits for a device advertisement matching one of the scanFilters.
  // It listens for the cancellationPromise to reject so that it can clean up its listener.
  function waitForDevice() {
    let listener;
    const devicePromise = new Promise((resolve) => {
      listener = function(event) {
        const detail = event.detail;
        if (detail && detail.method === "deviceInfo") {
          const device = detail.params || {};
          for (const filter of scanFilters) {
            if (matchesFilter(device, filter)) {
              removeEventListener("SmartcarSDKBLEResponse", listener);
              resolve(device);
              break;
            }
          }
        }
      };
      addEventListener("SmartcarSDKBLEResponse", listener);
    });

    return Promise.race([
      devicePromise,
      cancellationPromise.catch((err) => {
        removeEventListener("SmartcarSDKBLEResponse", listener);
        throw err;
      })
    ]);
  }

  // Main operation promise that loops until gattFlow succeeds or cancellation occurs.
  let operationPromise = new Promise(async (resolve, reject) => {
    try {
      // Start scanning if not already in progress.
      if (!scanInProgress) {
        await startScan();
      }
      while (true) {
        let device;
        try {
          // console.log("Waiting for device...");
          device = await Promise.race([waitForDevice(), cancellationPromise]);
          // console.log("Found device:", device);
        } catch (err) {
          // console.log("Did not find device:", err);
          errorHandler(err, "failed to find device");
          reject(err);
          return;
        }

        currentDevice = device;
        try {
          // Attempt to connect to the device.
          // console.log("Connecting to device:", device.address);
          const connectionResult = await connectGATT(device.address);
          // Run the provided gattFlow function.
          // console.log('Running gattFlow...', connectionResult);
          const gattResult = await gattFlow(device.address, connectionResult);
          // On success, resolve with the gattFlow result.
          // console.log("gattFlow succeeded:", gattResult);
          resolve(gattResult);
          break;
        } catch (err) {
          console.error("gattFlow error, retrying", err);
          errorHandler(err, "gattFlow error, retrying");
        } finally {
          // If gattFlow fails, disconnect (if needed) and retry with a new device.
          try {
            if (currentDevice && currentDevice.address) {
              await disconnectGATT(currentDevice.address);
            }
          } catch (_) {
            // Ignore disconnect errors.
          }
          currentDevice = null;
        }
      }
    } catch (err) {
      errorHandler(err, "bleReliableFlow error");
      reject(err);
    } finally {
      // Stop scanning when the operation completes or errors out.
      await stopScan();
    }
  });
  // console.log("Operation promise:", operationPromise, 'cancel:', cancel);
  return { promise: operationPromise, cancel };
}

export {
  connectGATT,
  disconnectGATT,
  readCharacteristic,
  writeCharacteristic,
  startNotifications,
  stopNotifications,
  nextNotification,
  bleReliableFlow,
};

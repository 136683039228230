/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

import appConfig from 'appConfig';

import defaultError from '../../payloads/default-error';

import api from '../../services/api';
import errorHandler from '../../services/sentry';

import { stripTrailingSlashes } from '../../utils';

import Page from '../Page';
import {
  Button,
  CopyText,
  Form,
  Header,
  Paragraph,
} from '../../components';

import { IProps } from '../../interfaces/pages/authorize.interface';
import { ISelectionsContext } from '../../interfaces/contexts/selections.interface';
import { SelectionsContext } from '../../contexts/Selections';

const componentMap = {
  button: Button,
  copyText: CopyText,
  form: Form,
  header: Header,
  paragraph: Paragraph,
};

const navigationMap = {
  '/oauth/authorize': {
    navigateTo: '/preamble',
    validationRoute: '/oauth/authorize',
  },
  '/oauth/reauthenticate': {
    navigateTo: '/reauthenticate/login',
    validationRoute: '/reauth/reauthenticate',
  },
  '/battery-capacity': {
    navigateTo: '/battery-capacity/select',
    validationRoute: '/battery-capacity',
  },
};

const Authorize = ({
  componentTree, isAuthorized, pageMetadata, setIsAuthorized, setComponentTree, setPreambleData,
}: IProps) => {
  const navigate = useNavigate();
  // send query params to backend for authorization
  const location = useLocation();
  const [redirect, setRedirect] = useState('');

  const cleanedPath = stripTrailingSlashes(location.pathname) as '/oauth/authorize' | '/oauth/reauthenticate' | '/battery-capacity';

  const context = useContext<ISelectionsContext | undefined>(SelectionsContext);

  // if authorization success, redirect to /preamble page
  useEffect(() => {
    if (isAuthorized) {
      navigate(redirect);
    }
  }, [isAuthorized]);

  const authorize = useCallback(async () => {
    // fetch initial token if timed tokens are enabled
    if (appConfig.timedTokenEnabled) {
      await api.getInitialToken();
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data } = await api.getComponents(
      `${navigationMap[cleanedPath].validationRoute}${location.search}`,
      false,
    );
    api.clearSessionToken();
    // save sessionId to fetch session token later
    localStorage.setItem('session-id', data.metadata.sessionId);

    if (data.metadata.selectedLanguage && data.metadata.selectedCountry) {
      context?.setLanguage({ value: data.metadata.selectedLanguage });
      context?.setCountry({ value: data.metadata.selectedCountry });
    }
    if (setPreambleData) setPreambleData(data);

    setRedirect(data.redirect || '/preamble');
  }, []);

  useEffect(() => {
    authorize()
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      .then(() => setIsAuthorized(true))
      .catch((e: AxiosError) => {
        errorHandler(e);

        // if the error has a page payload sent with it, display the page payload
        if (e.response?.data && e.response?.data.metadata) {
          return setComponentTree(e.response?.data);
        }
        return setComponentTree(defaultError(e.response?.status));
      });
  }, [authorize]);

  return Array.isArray(componentTree) && componentTree.length > 0 ? (
    <Page
      componentMap={componentMap}
      componentTree={componentTree}
      pageMetadata={pageMetadata}
      setComponentTree={setComponentTree}
    />
  ) : (
    <div className="page-spinner">
      <span className="spinner" />
    </div>
  );
};

export default Authorize;

import { useModal } from '../../contexts/Modal';

interface ModalWrapperProps {
  children: React.ReactNode;
  value: string;
}

const ModalWrapper = ({ children, value }: ModalWrapperProps) => {
  const { view } = useModal();
  return (
    view === value && (
      <div className="modal-wrapper">
        <div className="modal">
          {children}
        </div>
      </div>
    )
  );
};

export default ModalWrapper;

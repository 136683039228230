import crypto from 'crypto';
import { Buffer } from 'buffer';
import {
  writeCharacteristic,
  startNotifications,
  nextNotification,
} from "./ble.js";
import { rivian } from "./rivian.proto.js";
const {
  Container,
  UnsignedMsgList,
  SignedMsgList,
  Signature,
  SignatureType,
  Msg,
  Auth,
  Credentials,
  ClientInfo,
  MobileInfo,
  Model
} = rivian;

const SERVICE_UUID = "72CDDCA3-AB00-4E94-BAE5-868F93F8C6C0";
const PLAIN_DATA_IN_UUID = "0823DA14-040B-4914-BF7C-450AFA2850DA";
const PLAIN_DATA_OUT_UUID = "29919A3C-A697-4A6F-BD3B-D14860CC9BCE";
const ENCRYPTED_DATA_OUT_UUID = "5EAA65C0-57EE-4CF4-A3D5-A4AAE20CBB0B";

/* Create a SignedMsgList to send to the vehicle */
function createSignedMsgList(context, msgs) {
  const unsigned = UnsignedMsgList.create({ msgs });
  const msgsData = UnsignedMsgList.encode(unsigned).finish();

  const excludesDeviceNonce = typeof context.pNonce === "undefined";
  const excludesVehicleNonce = typeof context.vNonce === "undefined";

  const sequenceNum = context.sequenceNum++;

  const verificationData = Buffer.concat([
    msgsData,
    encodeUint32LE(sequenceNum),
    context.phoneId,
    excludesDeviceNonce ? Buffer.alloc(0) : context.pNonce,
    excludesVehicleNonce ? Buffer.alloc(0) : context.vNonce,
  ]);

  const hmac = crypto.createHmac("sha256", context.hmacSecret);
  const signature = hmac.update(verificationData).digest();

  const signed = SignedMsgList.create({
    msgsData,
    sequenceNum,
    excludesDeviceNonce,
    excludesVehicleNonce,
    signature: Signature.create({
      type: SignatureType.HMAC_SHA256,
      data: signature,
    })
  });
  return signed;
}

/* Validates a SignedMsgList received from the vehicle */
function validateSignedMsgList(context, sml) {
  const verificationData = Buffer.concat([
    sml.msgsData,
    encodeUint32LE(sml.sequenceNum),
    context.vehicleId,
    sml.excludesDeviceNonce ? Buffer.alloc(0) : context.pNonce,
    sml.excludesVehicleNonce ? Buffer.alloc(0) : context.vNonce,
  ]);

  const hmac = crypto.createHmac("sha256", context.hmacSecret);
  const signature = hmac.update(verificationData).digest();

  return signature.equals(sml.signature.data);
}

function encodeUint32LE(val) {
  const buffer = Buffer.alloc(4);
  buffer.writeUint32LE(val);
  return buffer;
}

function getTransactionId(context) {
  const transactionId = context.transactionId;
  context.transactionId += 2;
  return transactionId;
}

/**
 * Authorize with the vehicle using Rivian 2025+ protocol.
 * Scanning and connecting to the vehicle is handled in {@link rivian.js}.
 */
export async function authorize(address, hmacSecret, vehicleId, phoneId) {
  const context = {
    /* sent with Msg. increments by 2 each Msg */
    transactionId: 1,
    /* sent with SignedMsgList. increments by 1.
     * in app, this starts with 0 if "isPreCCC" is false
     */
    sequenceNum: 1,
    hmacSecret: Buffer.from(hmacSecret, "hex"),
    phoneId: Buffer.from(phoneId.replace(/-/g, ""), "hex"),
    vehicleId: Buffer.from(vehicleId.replace(/-/g, ""), "hex"),
    pNonce: crypto.randomBytes(16),
    vNonce: undefined, // to be received from vehicle
  };

  await startNotifications(address, SERVICE_UUID, PLAIN_DATA_OUT_UUID);

  /* Send phone ID and nonce */
  const msg1 = Msg.create({
    transactionId: getTransactionId(context),
    auth: Auth.create({
      credentials: Credentials.create({
        id: context.phoneId,
        nonce: context.pNonce,
      }),
    }),
  });
  const c1 = Container.create({
    signedMsgs: createSignedMsgList(context, [msg1])
  });
  const payload1 = Container.encode(c1).finish();

  // Set up listener for vehicle response
  const vehicleResponsePromise = nextNotification(
    address, SERVICE_UUID, PLAIN_DATA_OUT_UUID
  );
  // Send auth message
  await writeCharacteristic(
    address,
    SERVICE_UUID,
    PLAIN_DATA_IN_UUID,
    payload1,
  );

  /* Handle vehicle Auth response */
  const vehicleResponse = await vehicleResponsePromise;
  const vehicleAuth = Container.decode(vehicleResponse);
  const vehicleMsgs = UnsignedMsgList.decode(vehicleAuth.signedMsgs.msgsData);
  const credentials = vehicleMsgs.msgs[0].auth.credentials;
  const vNonce = credentials.nonce;
  if (validateSignedMsgList({...context, vNonce}, vehicleAuth.signedMsgs)) {
    // validation success
  } else {
    // validation failed
    return;
  }
  // continue with vNonce even if validation fails for testing
  context.vNonce = vNonce;
  if (!context.vehicleId.equals(credentials.id)) {
    return;
  }

  /* Send signed params */
  const msgs = [
    // auth with empty credentials
    Msg.create({
      transactionId: getTransactionId(context),
      auth: Auth.create({
        credentials: Credentials.create(),
      }),
    }),
    // client info msg
    Msg.create({
      transactionId: getTransactionId(context),
      clientInfo: ClientInfo.create({
        mobileInfo: MobileInfo.create({
          model: Model.PIXEL_6,
          modelRaw: "pixel 6",
        })
      })
    })
  ];
  const c2 = Container.create({
    signedMsgs: createSignedMsgList(context, msgs)
  });
  const payload2 = Container.encode(c2).finish();

  await writeCharacteristic(
    address,
    SERVICE_UUID,
    PLAIN_DATA_IN_UUID,
    payload2,
  );

  // Start notification on protected characteristic to trigger bonding
  await startNotifications(address, SERVICE_UUID, ENCRYPTED_DATA_OUT_UUID);
}

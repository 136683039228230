import crypto from 'crypto';
import {
  writeCharacteristic,
  startNotifications,
  nextNotification,
} from "./ble.js";

export const SERVICE_UUID = "52495356-454e-534f-5253-455256494345";
const PHONE_ID_VEHICLE_ID_UUID = "AA49565A-4D4F-424B-4559-5F5752495445";
const PHONE_NONCE_VEHICLE_NONCE_UUID = "E020A15D-E730-4B2C-908B-51DAF9D41E19";
const ACTIVE_ENTRY_CHARACTERISTIC_UUID = "5249565F-4D4F-424B-4559-5F5752495445";
// can also try this one
// const VEHICLE_MESSAGE_UUID = "5ae32b92-eafb-471b-afe8-e88eec4a4774";

/**
 * Authorize with the vehicle using Rivian legacy protocol.
 * Scanning and connecting to the vehicle is handled in {@link rivian.js}.
 */
export async function authorize(address, hmacSecret, vehicleId, phoneId) {
  const hmac = crypto.createHmac("sha256", Buffer.from(hmacSecret, "hex"));
  const phoneNonce = crypto.randomBytes(16);
  const signature = hmac.update(phoneNonce).digest();

  const vehicleIdNotificationPromise = nextNotification(
    address,
    SERVICE_UUID,
    PHONE_ID_VEHICLE_ID_UUID
  );
  const nonceNotificationPromise = nextNotification(
    address,
    SERVICE_UUID,
    PHONE_NONCE_VEHICLE_NONCE_UUID
  );
  await startNotifications(address, SERVICE_UUID, PHONE_ID_VEHICLE_ID_UUID);
  await startNotifications(address, SERVICE_UUID, PHONE_NONCE_VEHICLE_NONCE_UUID);

  // Write phoneId to the characteristic
  await writeCharacteristic(
    address,
    SERVICE_UUID,
    PHONE_ID_VEHICLE_ID_UUID,
    Buffer.from(phoneId.replace(/-/g, ""), "hex") // hex string
  );

  // Wait for the device to notify us with the vehicle_id
  const vehicleIdResponse = await vehicleIdNotificationPromise;
  if (!Buffer.from(vehicleId.replace(/-/g, ""), "hex").equals(vehicleIdResponse)) {
    return;
  }

  // Write phoneNonce + signature
  await writeCharacteristic(
    address,
    SERVICE_UUID,
    PHONE_NONCE_VEHICLE_NONCE_UUID,
    Buffer.concat([phoneNonce, signature])
  );

  // Wait for vehicle to respond
  const signedVehicleNonce = await nonceNotificationPromise;

  // Attempt to verify vehicle nonce
  const vehicleNonce = signedVehicleNonce.subarray(0, 16);
  const vehicleSignature = signedVehicleNonce.subarray(16);

  const hmac2 = crypto.createHmac("sha256", Buffer.from(hmacSecret, "hex"));
  hmac2.update(phoneNonce);
  hmac2.update(vehicleNonce);
  const expectedSignature = hmac2.digest();

  if (!vehicleSignature.equals(expectedSignature)) {
    throw new Error("Signature mismatch!");
  }

  // Start notification on protected characteristic to trigger bonding
  await startNotifications(address, SERVICE_UUID, ACTIVE_ENTRY_CHARACTERISTIC_UUID);
}
